import React, { useEffect, useState } from 'react'
import './verify.scss'
import Navbar from '../landing/header/Navbar'
// import TwitterLogin from 'react-twitter-auth';

const Verify = () => {
    const [oauthVerifier1, setOauthVerifier1] = useState(null)
    const [oauthToken1, setOauthToken1] = useState(null)
    const onSuccess = (response) => {
        // console.log("respnce", response?.url)
        const parsedUrl = new URL(response?.url);

        // Get the search parameters from the URL
        const searchParams = parsedUrl.searchParams;

        // Get the values of oauth_verifier and oauth_token
        const oauthVerifier = searchParams.get('oauth_verifier');
        const oauthToken = searchParams.get('oauth_token');
        // if(oauthVerifier != '' & oauthToken != ''){
        //     getvalueUserid(oauthVerifier, oauthToken)
        // }
        // console.log('OAuth Verifier:', oauthVerifier);
        // console.log('OAuth Token:', oauthToken);
        if (response.status === 200) {
            // setTwitterAutization(true)
            setOauthVerifier1(oauthVerifier)
            setOauthToken1(oauthToken)
            // toast.success("X authorized accessfully")

            // } else if (response.status == 400) {
            //     toast.error('X account must be created 30 days before')
            // } else {
            //     toast.error('Something went wrong')
            // }

        }
        // Handle the authentication success here
    };

    const onFailed = (error) => {
        // console.error('Twitter Auth Error:', error);
        // toast.error('error')
        // setRestTweeter(false)
        // Handle the authentication error here
    };

    return (
        <>
            <div className="eligiblewrapper">
                <Navbar />
                <section className="verifymain">
                    <div className="innerverify">
                        <img src="\assets\doplogo.svg" alt="logoimg" className="logoimg" />
                        <h4 className="verifyhead">Verify that you still follow DOP on X</h4>
                        <p className="verifypara">Verify your twitter account to see that you are eligible to buy or not</p>
                        <div className="btnmain">
                        {/* <TwitterLogin
                                                                loginUrl="https://rewards-api.dop.org/rewards/auth/twitter"
                                                                onFailure={onFailed}
                                                                onSuccess={onSuccess}
                                                                requestTokenUrl="https://rewards-api.dop.org/rewards/auth/twitter/reverse"
                                                            /> */}
                     
                        <p className="btnpara">
                        Sign into your <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                            <path d="M0.0365712 0.978149L5.82786 8.76361L0 15.0935H1.31162L6.41389 9.55159L10.5364 15.0935H14.9999L8.88275 6.87014L14.3073 0.978149H12.9957L8.29671 6.08216L4.50005 0.978149H0.0365712ZM1.9654 1.94952H4.01593L13.0707 14.122H11.0202L1.9654 1.94952Z" fill="white" />
                        </svg> account
                        </p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Verify