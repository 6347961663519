import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/Navbar'
import './welcometimer.scss'
import Loader from "../../hooks/loader";
import { useHistory } from "react-router-dom";
const Welcometimer = () => {
    const [timeshow, setTimeshow] = useState(false);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [loaderr1, setLoarder1] = useState(true);
    const history = useHistory();
  
    useEffect(() => {

        const interval = setInterval(() => {
            const now = new Date().getTime();
        const time = 1711439955000;
        const diff = time - now;
        setLoarder1(false)
            if (diff <= 0) {
                clearInterval(interval);
            
                localStorage.setItem("timer",true)
                history.push("/");
                window.location.reload()
            
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);
            setDay(days);
            setHour(hours);
            setMin(mins);
            setSec(secs);
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
           {loaderr1 && (
        <Loader />
      )}
            <div className="welcomemainwrapper">
                <Navbar />
                <section className="mainwelcomenew">
                    <div className="innerwelcome">
                        <img src="\assets\doplogo.svg" alt="logoimg" className="logoimg" />
                        <h4 className="welcomemainhead">Welcome DOP testnet users!</h4>
                        <p className="welcomemainpara">You've played an important role in our journey. This exclusive sale is our way of saying thank you.</p>
                        <div className="votingtimer">
            <h6 className="votingtimerhead">Testnet Exclusive <br/>
Sale Round Starts in</h6>
            {timeshow ? (
              <p className="endpara">Timer has ended!</p>
            ) : (
              <div className="timerspans">
                     <div className="maintime">
                  <div className="innertimer">
                    <span className="innertimertext">{day ? day : 0}</span>
                  </div>
                  <p className="timertext">DAYS</p>
                </div>
                <div className="maintime">
                  <div className="innertimer">
                    <span className="innertimertext">{hour ? hour : 0}</span>
                  </div>
                  <p className="timertext">HOURS</p>
                </div>
                <div className="maintime">
                  <div className="innertimer">
                    <span className="innertimertext">{min ? min : 0}</span>
                  </div>
                  <p className="timertext">MINUTES</p>
                </div>
                <div className="maintime">
                  <div className="innertimer">
                    <span className="innertimertext">{sec ? sec : 0}</span>
                  </div>
                  <p className="timertext">SECONDS</p>
                </div>
              </div>
            )}
          </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Welcometimer