import React, { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../header/Navbar";
import Modal from "react-bootstrap/Modal";
import EthPrice from "../../../hooks/chinaFunction/ethPrice";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import moment from "moment";
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import AllowanceTpl1 from "../../../hooks/dataFetchers/AllowanceTpl1";
import ApproveTpl from "../../../hooks/dataFetchers/ApproveTpl";
import ApproveTplUsdc from "../../../hooks/dataFetchers/ApproveTplUsdc";
import ApproveTplWbtc from "../../../hooks/dataFetchers/ApproveTplWbtc";
import ApproveTomi from "../../../hooks/dataFetchers/ApproveTomi";
import ApproveTomi1 from "../../../hooks/dataFetchers/ApproveTomi1";
import ApproveTpl1 from "../../../hooks/dataFetchers/ApproveTpl1";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import PurchaseEth from "../../../hooks/chinaFunction/purchaseEth";
import PurchaseEth1 from "../../../hooks/chinaFunction/purchaseEth1";
import PurchaseUsdt from "../../../hooks/chinaFunction/purchaseUsdt";
import PurchaseUsdc from "../../../hooks/chinaFunction/purchaseUsdc";
import PurchaseWbtc from "../../../hooks/chinaFunction/purchaseWbtc";
import PurchaseUsdt1 from "../../../hooks/chinaFunction/purchaseUsdt1";
import Loader from "../../../hooks/loader";
import axios from "axios";
import { API_URL, API_URL1 } from "../../../utils/ApiUrl";
import { toast } from "react-toastify";
import CuurentRound from "../../../hooks/chinaFunction/round";
import CuurentRoundNft from "../../../hooks/chinaFunction/roundNft";
import SaleStatus from "../../../hooks/chinaFunction/saleStatus";
import SaleStatusNft from "../../../hooks/chinaFunction/saleStatusNft";
import SaleStatus1 from "../../../hooks/chinaFunction/saleStatus1";
import Timer from "../../../hooks/chinaFunction/timer";
import TimerNft from "../../../hooks/chinaFunction/timeNft";
import { useHistory } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DopNft from "../../DopNft/DopNft";
import BalacefAccountTomi from "../../../hooks/dataFetchers/BlanaceOfAccountTom";
import BalacefAccountUsdc from "../../../hooks/dataFetchers/BalanceOfAccountUsdc";
import BalacefAccountWbtc from "../../../hooks/dataFetchers/BalanceOfAccountWbtc";
import AllowanceTomi from "../../../hooks/dataFetchers/AllowanceTomi";
import AllowanceUsdc from "../../../hooks/dataFetchers/AllowanceUsdc";
import AllowanceWbtc from "../../../hooks/dataFetchers/AllowanceWbtc";
import AllowanceTomi1 from "../../../hooks/dataFetchers/AllowanceTomi1";
import PurchaseTomi from "../../../hooks/chinaFunction/purchaseTomi";
import PurchaseTomi1 from "../../../hooks/chinaFunction/puchaseTomi1";
import DiscountedPrice from "../../../hooks/chinaFunction/discountedPrice";
import Environment from "../../../utils/Environment";
import WbtcPrice from "../../../hooks/chinaFunction/wbtcPrice";
import { setupNetwork } from "../../../utils/wallet";
import StartTime from "../../../hooks/chinaFunction/startTime";
import EndTime from "../../../hooks/chinaFunction/endTime";
import CurrentNewPrice from "../../../hooks/chinaFunction/tokenNewPrice";
import PurchaseCheck from "../../../hooks/chinaFunction/puchaseCheck";
import { ProgressBar } from "react-bootstrap";
const Banner = ({ setShow }) => {
    let timerrr = localStorage.getItem("timer")
    let modalll = localStorage.getItem("modalll")
    // const [checkedNew,setCheckedNew]=useState(false)
  const [maxPrice, setMaxPrice] = useState(null)
  const [maxPrice1, setMaxPrice1] = useState(0.5)
  const [maxPrice2, setMaxPrice2] = useState(null)
  const history = useHistory();
  const { startTime } = StartTime()
  const { endTime } = EndTime()
  const { currentNewPrice } = CurrentNewPrice()
  const { purchaseCheck } = PurchaseCheck()
  const { allowanceTomi } = AllowanceTomi()
  const { allowanceUsdc } = AllowanceUsdc()
  const { allowanceWbtc } = AllowanceWbtc()
  const { allowanceTomi1 } = AllowanceTomi1()
  const { cuurentRound } = CuurentRound()
  const { cuurentRoundNft } = CuurentRoundNft()
  const { saleStatus } = SaleStatus()
  const { saleStatusNft } = SaleStatusNft()
  const { saleStatus1 } = SaleStatus1()
  const { timer } = Timer()
  const { timerNft } = TimerNft()
  const { purchaseTomi } = PurchaseTomi()
  const { purchaseTomi1 } = PurchaseTomi1()
  const { discountedPrice } = DiscountedPrice()
  const [startTtime, setStartTtime] = useState(null)
  const [eendTime, setEendime] = useState(null)
  var now11 = new Date();
  let createdDate = now11?.getTime()
  // console.log(createdDate,'createdDate');
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime)
  // console.log(currentTime,'currentTime');
  const code = localStorage?.getItem("accessCode");
  const instiPrice = localStorage?.getItem("price_ins");
  const role = localStorage?.getItem("role_ins");
  const email = localStorage?.getItem('email')
  const [id, setId] = useState(null)
  const [price, setPrice] = useState(null)
  const [round, setRound] = useState(null)
  const [loaderr, setLoarder] = useState(false);
  const [loaderr1, setLoarder1] = useState(true);
  const { purchaseUsdt } = PurchaseUsdt();
  const { purchaseUsdc } = PurchaseUsdc()
  const { purchaseWbtc } = PurchaseWbtc()
  const { purchaseEth } = PurchaseEth();
  const { purchaseUsdt1 } = PurchaseUsdt1();
  const { purchaseEth1 } = PurchaseEth1();
  const { approveTpl } = ApproveTpl();
  const { approveTplUsdc } = ApproveTplUsdc()
  const { approveTplwbtc } = ApproveTplWbtc()
  const { approveTomi } = ApproveTomi()
  const { approveTomi1 } = ApproveTomi1()
  const { approveTpl1 } = ApproveTpl1();
  const { allowanceTpl } = AllowanceTpl();
  const { allowanceTpl1 } = AllowanceTpl1();
  const { balacefAccount } = BalacefAccount();
  const { balacefAccountTomi } = BalacefAccountTomi()
  const { balacefAccountUsdc } = BalacefAccountUsdc()
  const { balacefAccountWbtc } = BalacefAccountWbtc()
  const [currentRoundd, setCurrentRoundd] = useState(null)
  const [copyOfPrice, setCopyOfPrice] = useState(null)
  const [priceCheck, setPriceCheck] = useState(false)
  const web3 = useWeb3();
  let now = Date.now();
  const { ethPrice } = EthPrice();
  const { wbtcPrice } = WbtcPrice()
  const [inputFeild5, setInputField5] = useState(null);
  const [inputFeild, setInputField] = useState(null);
  const [inputFeild1, setInputField1] = useState(null);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [blanceOfEth, setBlanceOfEth] = useState(0);
  const [date, setDate] = useState(0);
  const [mainTag, setMainTag] = useState("ETH");
  const [mainTag1, setMainTag1] = useState("USDT");
  const { account, chainId } = useWeb3React();
  const [balanceeUsdt, setBalanceeUsdt] = useState(null);
  const [sallowance, setAllowance] = useState(null);
  const [getAllowanceUsdc, setAllowanceUsdc] = useState(null);
  const [getAllowanceWbtc, setAllowanceWbtc] = useState(null);
  const [sallowance1, setAllowance1] = useState(null);
  const [currentRount, setCurrentRount] = useState(null)
  const [currentTimer, setCurrentTimer] = useState(null)
  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [saleCurrentStaus, setSaleCurrentStaus] = useState(false);
  const [saleCurrentStaus1, setSaleCurrentStaus1] = useState(false);
  const [check, setCheck] = useState(false)
  const [amountInUsdt, setAmountInUsdt] = useState(null);
  const [niceName, setNicName] = useState(null)
  // console.log(niceName,'niceName');
  const [tab, setTab] = useState('home')
  const [tagImge, setTagImge] = useState('/assets/eth-icon.svg')
  const [tomiPrice, setTomiPrice] = useState(null)
  const [wbtcCurrentPrice, setWbtcCurrentPrice] = useState(null)
  // const [usdcCurrentPrice,setUsdcCurrentPrice]=useState(null)
  const [balaceOfTmi, setBalanceOfTomi] = useState(null)
  const [balaceOfUsdc, setBalanceOfUsdc] = useState(null)
  const [balaceOfWbtc, setBalanceOfWbtc] = useState(null)
  const [tomiAllowance, setTomiAllwance] = useState(null)
  const [tomiAllowance1, setTomiAllwance1] = useState(null)
  const [percent, setPercet] = useState(0)
  const [cheked,setCheked] = useState(false)
  const [cheked1,setCheked1]=useState(false)
  const inputField1Handler2 = (e) => {
  
    if (e.target.checked == true) {
      setCheked(true)
    } else {
      setCheked(false)
      setCheked1(false)
    }
  }
  const inputField1Handler3 = (e) => {
    if (e.target.checked == true) {
      setCheked1(true)
    } else {
      setCheked1(false)
    }
  }
  console.log(account, 'saleCurrentStaus',);
  useEffect(() => {
    firstTimeData()
    firstTimeData11()
    saleStatusss()
    // alowence();
    // getTomiPrice()
    getWbtcCurrentPrice()
    if (account) {
      balaceOf();
      firstTimeData1();
      balancerOftomi()
      balancerOfUsdc()
      balancerOfWbtc()

    } else {
      setBalanceeUsdt(null);
      setBlanceOfEth(null);
      setBalanceOfTomi(null)
      setBalanceOfUsdc(null);
      setBalanceOfWbtc(null)
    }
  }, [account, maxPrice1, chainId,web3]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     firstTimeData();
  //     firstTimeData1();
  //   }, 30000); 

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [account]);

  // console.log(sallowance,'sajad',inputFeild);

  const saleStatusss = async () => {
    try {
      let b = await saleStatus()
      let b1 = await saleStatus1()
      if (b || b1) {
        // console.log(b1, 'noooor imad');
        // setCurrentRoundd(b)
        // roundData(b)
        setSaleCurrentStaus(b)
        setSaleCurrentStaus1(b1)
      }
    } catch (err) {
      console.log(err.message, 'nooor');
    }
  };


  const firstTimeData = async () => {
    if (maxPrice1) {
      setLoarder1(true)
      try {

        let price = await currentNewPrice()
        let strtime = await startTime()
        let endtime = await endTime()
        let insvest = await purchaseCheck()
        console.log(price, strtime, endtime, insvest, 'bhome current ');
        if (price || strtime || endtime || insvest) {
          // setCurrentRoundd(dummy[0]?.currentround)
          // userLogin1(dummy[0]?.currentround)
          setPrice(price / 10 ** 18)
          setStartTtime(strtime)
          setEendime(endtime)
          let calculate = parseInt(insvest) / 10 ** 18
          setMaxPrice(calculate)
          setMaxPrice2(maxPrice1 - calculate)
          setTimeout(() => {
            console.log("Delayed for 1 second.");
            setLoarder1(false)
          }, "5000");

        }

        // if (b) {
        // console.log(b, 'noooor');
        // setCurrentRoundd(b)
        // roundData(b)
        // }
      } catch (err) {
        console.log(err, 'nooor bhome current');
        // setLoarder1(false)
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          setLoarder1(false)
        }, "5000");

      }
    }
  };
  // console.log(ethPricevalue,'ethPricevalue');
  const firstTimeData11 = async () => {
    try {
      let a = await ethPrice();
      if (a) {
        console.log(a,'aaa');
        setEthPrice(a);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const firstTimeData1 = async () => {
    try {
      var balance = await web3.eth.getBalance(account);
      setBlanceOfEth(balance / 10 ** 18);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getTomiPrice = async () => {
    try {
      const config = {
        method: "get",
        url: `${API_URL}users/tomi-price`
      };
      const postApiRes = await axios(config)
        .then(function (response) {
          let qw = response?.data?.data
          setTomiPrice(qw)
        })
    } catch (error) {
      console.log("coin market cap error::", error);
    }
  };
  const getWbtcCurrentPrice = async () => {
    try {
      let a = await wbtcPrice();
      if (a) {
        // console.log(a,'aaa');
        setWbtcCurrentPrice(a)
      }
    } catch (err) {
      console.log(err.message);
    }

  };

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => {
  
    setShow22(false);
  };
  const handleShow22 = () => {

    setShow22(false);
  };
  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [checkedNew,setCheckedNew]=useState(false)
  const handleCategoryScroll = () => {
    console.log("hitting handle Scroll ");

    const element = document.querySelector(".scrolltext");
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
      !isCategoryLoading
    ) {
      setIsCategoryLoading(true);
    }
  };



const sxcrolll=()=>{
  console.log('completed');
  setIsCategoryLoading(false)
  setCheckedNew(true)
}

  const [show1, setShow1] = useState(false);
  const handleClose = () => {
    setInputField("");
    setInputField1("");
    setShow1(false);
  };
  const handleShow = () => setShow1(true);
  const changeTag = () => {
    if (mainTag === "ETH") {
      setMainTag("USDT");
      setMainTag1("ETH");
      setInputField1('');
      setInputField('');
      // }
    } else {
      setMainTag("ETH");
      setMainTag1("USDT");
      setInputField1('');
      setInputField('');
    }
  };
  const changeTag1 = (e) => {
    if (e === "ETH") {
      discoundedPriceee(currentRoundd, Environment.eth)
      setMainTag("ETH");
      setTagImge('/assets/eth-icon.svg')
      // setMainTag1("ETH");
      setInputField1('');
      setInputField('');
      // }
    } else if (e == "USDT") {
      discoundedPriceee(currentRoundd, Environment.busd)
      setMainTag("USDT");
      setTagImge('/assets/usdt.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
    }
    else if (e == "USDC") {
      discoundedPriceee(currentRoundd, Environment.usdc)
      setMainTag("USDC");
      setTagImge('/assets/usdc.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
    }
    else if (e == "WBTC") {
      discoundedPriceee(currentRoundd, Environment.wbtc)
      setMainTag("WBTC");
      setTagImge('/assets/wbtc.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');
    }
    else {
      discoundedPriceee(currentRoundd, Environment.tomi)
      setMainTag("TOMI");
      setTagImge('/assets/tomi-icon-drop.svg')
      // setMainTag1("USDT");
      setInputField1('');
      setInputField('');

    }
  };
  const inputField1Handler = (e) => {
    // console.log(ethPricevalue, value, price, 'sdfsfs');
    setAmountInUsdt('')
    let value = e.target.value;
    if (mainTag === "ETH") {
      if (value) {
        console.log(ethPricevalue ,value,price,'xccdssdvcd');
        let amount = parseFloat(((ethPricevalue * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((ethPricevalue * value).toFixed(4))
        setInputField(value);
      } else {
        setInputField1("");
        setInputField("");
      }
    } else if (mainTag === "USDT") {
      if (value) {
        let amount = parseFloat(value / price).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setInputField(value);
        setAmountInUsdt(value)
      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else if (mainTag === "USDC") {
      if (value) {
        let amount = parseFloat(value / price).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setInputField(value);
        setAmountInUsdt(value)
      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else if (mainTag === "WBTC") {
      if (value) {
        let amount = parseFloat(((wbtcCurrentPrice * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((wbtcCurrentPrice * value).toFixed(4))
        setInputField(value);
      } else {
        setInputField1("");
        setInputField("");
      }
    }



    else {
      if (value) {
        let amount = parseFloat(((tomiPrice * value) / price)).toFixed(6)
        let amount1 = ''
        if (price > 0) {
          amount1 = Math.round((amount) * 1e12) / 1e12;
        } else {
          amount1 = 0.00
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setAmountInUsdt((tomiPrice * value).toFixed(4))
        setInputField(value);
      } else {
        setInputField1("");
        setInputField("");
      }
    }
  };
  const inputField1Handler1 = (e) => {

    let value = e.target.value;
    if (mainTag === "ETH") {
      if (value) {
        let amount = parseFloat((value) * price / ethPricevalue).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(parseFloat(value));

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else if (mainTag === "USDT") {
      if (value) {
        let amount = parseFloat(value * price).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(value);
      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else if (mainTag === "USDC") {
      if (value) {
        let amount = parseFloat(value * price).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(value);
      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else if (mainTag === "WBTC") {
      if (value) {
        let amount = parseFloat((value) * price / wbtcCurrentPrice).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(parseFloat(value));


      } else {
        setInputField1("");
        setInputField("");
      }
    }
    else {
      if (value) {
        let amount = parseFloat((value) * price / tomiPrice).toFixed(6)
        let amount1 = Math.round((amount) * 1e12) / 1e12;
        setInputField(parseFloat(amount1)?.toFixed(2));
        setInputField1(parseFloat(value));

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    }
  };
  const balaceOf = async () => {
    try {
      let balace = await balacefAccount(account);
      if (balace) {
        setBalanceeUsdt(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOftomi = async () => {
    try {
      let balace = await balacefAccountTomi(account);
      if (balace) {
        setBalanceOfTomi(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfUsdc = async () => {
    try {
      let balace = await balacefAccountUsdc(account);
      if (balace) {
        setBalanceOfUsdc(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balancerOfWbtc = async () => {
    try {
      let balace = await balacefAccountWbtc(account);
      if (balace) {
        setBalanceOfWbtc(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const alowence = async () => {
    try {
      let r = await allowanceTpl();
      setAllowance(r);
      let w = await allowanceTomi()
      setTomiAllwance(w)
      let r1 = await allowanceTpl1();
      setAllowance1(r1);
      let da = await allowanceTomi1()
      setTomiAllwance1(da)
      let usdcc = await allowanceUsdc()
      setAllowanceUsdc(usdcc)
      let wbtcc = await allowanceWbtc()
      setAllowanceWbtc(wbtcc)
      // console.log(r,w,'nooooer',);

    } catch (err) {
      console.log(err, 'nooooer23');
    }
  };
  const handlerPop = async () => {
    if (inputFeild && inputFeild1) {
      if (chainId !=1) {
        let b = await setupNetwork()
        if (b) {
          confirm();
        }
      } else {
        confirm();
      }
    }
  };
  const confirm = async () => {
    console.log('click ho gaya', role, mainTag);
    let postApiRes = ''
    var config = ''
    if (role === 'institution') {
      if (mainTag == 'ETH') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5?.toString()

          },
        };
      } else if (mainTag == 'USDT') {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-with-stable-coin`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5.toString()

          },
        };
      } else {
        config = {
          method: "post",
          url: `${API_URL}users/institutional-buy-with-tomi`,
          data: {
            accessCode: code,
            walletAddress: inputFeild5?.toString()

          },
        };
      }

      postApiRes = await axios(config)
        .then(function (response) {
          return response?.data?.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data.message);
        });
    }
    else {
      if (mainTag == 'TOMI') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-tomi`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            // buyingAmount: amountInUsdt,
          },
        }
      } else if (mainTag == 'ETH') {

        config = {
          method: "post",
          url: `${API_URL}testnet/buy`,
          data: {
            // accessCode: code,
            walletAddress: account?.toLowerCase(),
            buyingAmount: inputFeild,

          },
        }
      }
      else if (mainTag == 'WBTC') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-wbtc`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),

          },
        };
      }
      else if (mainTag == 'USDC') {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-stable-coin-usdc`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),

          },
        };
      }

      else {
        config = {
          method: "post",
          url: `${API_URL}users/buy-with-stable-coin`,
          data: {
            accessCode: code,
            walletAddress: account.toString(),
            // buyingAmount: amountInUsdt,
          },
        }
      };
      postApiRes = await axios(config)
        .then(function (response) {
          return response?.data?.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data.message);
        });
    }
    setLoarder(true);
    if (role === 'institution') {
      if (mainTag === "ETH") {
        try {
          if (postApiRes) {
            if (saleCurrentStaus1) {
              let b = blanceOfEth - parseFloat(inputFeild)
              let x = await purchaseEth1(parseFloat(inputFeild1), parseFloat(inputFeild), postApiRes, inputFeild5, b);
              if (x) {
                confirmInst(x, postApiRes)
                setLoarder(false);
                firstTimeData();
                firstTimeData11()
                firstTimeData1();
                balaceOf();
                alowence();
                // balaceOf1();
                handleShow();
              }
            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }
          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          toast.error("Transaction reverted:" + err.message);
        }
      } else if (mainTag === "USDT") {
        if (sallowance1 === 0) {
          try {
            let z = await approveTpl1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild >= sallowance1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let res = await approveTpl1(0, account);
                  if (res) {
                    let res11 = await approveTpl1(10000000, account);

                    if (res11) {
                      let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                      if (q) {
                        confirmInst(q, postApiRes)
                        setLoarder(false);
                        firstTimeData();
                        firstTimeData11()
                        firstTimeData1();
                        balaceOf();
                        alowence();
                        // balaceOf1();
                        handleShow();
                      }
                    }
                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseUsdt1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      } else {
        if (tomiAllowance1 === 0) {
          try {
            let z = await approveTomi1(10000000, account);
            if (postApiRes) {
              if (saleCurrentStaus1) {
                if (z) {
                  let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Crrently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            alowence();
            console.log(err, "err");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild >= tomiAllowance1) {
              if (postApiRes) {
                if (saleCurrentStaus1) {

                  let res11 = await approveTomi1(10000000, account);

                  if (res11) {
                    let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmInst(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (saleCurrentStaus1) {
                  let q = await purchaseTomi1(inputFeild, postApiRes, inputFeild5, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmInst(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            alowence();
            console.log(err, "errr");
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
    } else {
      if (mainTag === "ETH") {
        try {
          if (postApiRes) {
            if (!saleCurrentStaus) {
              let b = blanceOfEth - parseFloat(inputFeild)
              // console.log(b,'balance',gasPrice/10**9);
              let x = await purchaseEth(parseFloat(inputFeild1), parseFloat(inputFeild), postApiRes, currentRoundd, b);
              if (x) {
                // confirmBuy(x, postApiRes)
                setLoarder(false);
                firstTimeData();
                firstTimeData11()
                firstTimeData1();
                balaceOf();
                alowence();
                // balaceOf1();
                handleShow();
              }
            } else {
              toast.error("Sale is Currently Paused");
              setLoarder(false);
            }
          }
          else {
            setLoarder(false);
          }
        } catch (err) {
          console.log(err.message, "errr");
          setLoarder(false);
          toast.error("Transaction reverted:" + err.message);
        }
      } else if (mainTag === "USDT") {
        // console.log(sallowance, 'sallowance');
        if (sallowance === 0) {
          try {
            if (postApiRes) {
              if (!saleCurrentStaus) {
                let z = await approveTpl(10000000, account);
                if (z) {
                  let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > sallowance) {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let res = await approveTpl(0, account);
                  if (res) {
                    let res11 = await approveTpl(10000000, account);

                    if (res11) {
                      let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                      if (q) {
                        confirmBuy(q, postApiRes)
                        setLoarder(false);
                        firstTimeData();
                        firstTimeData11()
                        firstTimeData1();
                        balaceOf();
                        alowence();
                        // balaceOf1();
                        handleShow();
                      }
                    }
                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let q = await purchaseUsdt(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
      else if (mainTag === "USDC") {
        // console.log(getAllowanceUsdc, 'sallowance');
        if (getAllowanceUsdc === 0) {
          try {
            if (postApiRes) {
              if (!saleCurrentStaus) {
                let z = await approveTplUsdc(10000000, account);
                if (z) {
                  let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > getAllowanceUsdc) {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let res11 = await approveTplUsdc(10000000, account);

                  if (res11) {
                    let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let q = await purchaseUsdc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }


      else if (mainTag === "WBTC") {
        // console.log(sallowance, 'sallowance');
        if (getAllowanceWbtc === 0) {
          try {
            if (postApiRes) {
              if (!saleCurrentStaus) {
                let z = await approveTplwbtc(10000000, account);
                if (z) {
                  let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);

          }
        } else {
          try {
            if (inputFeild > getAllowanceWbtc) {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let res11 = await approveTplwbtc(10000000, account);

                  if (res11) {
                    let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // balaceOf1();
                      handleShow();
                    }

                  }


                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let q = await purchaseWbtc(inputFeild, postApiRes, currentRoundd, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }

      else {
        if (tomiAllowance === 0) {
          try {
            if (postApiRes) {
              if (!saleCurrentStaus) {
                let z = await approveTomi(10000000, account);
                if (z) {
                  let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, tomiPrice, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                }



              } else {
                toast.error("Sale is Currently Paused");
                setLoarder(false);
              }
            }


            else {
              setLoarder(false);
            }
          } catch (err) {
            console.log(err, "err");
            alowence();
            setLoarder(false);
            toast.error("Transaction reverted:" + err.message);
          }
        } else {
          try {
            if (inputFeild > tomiAllowance) {
              if (postApiRes) {
                if (!saleCurrentStaus) {

                  let res11 = await approveTomi(10000000, account);

                  if (res11) {
                    let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, tomiPrice, blanceOfEth, parseFloat(inputFeild1));
                    if (q) {
                      confirmBuy(q, postApiRes)
                      setLoarder(false);
                      firstTimeData();
                      firstTimeData11()
                      firstTimeData1();
                      balaceOf();
                      alowence();
                      // balaceOf1();
                      handleShow();
                    }
                  }



                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              } else {
                setLoarder(false);
              }
            } else {
              if (postApiRes) {
                if (!saleCurrentStaus) {
                  let q = await purchaseTomi(inputFeild, postApiRes, currentRoundd, tomiPrice, blanceOfEth, parseFloat(inputFeild1));
                  if (q) {
                    confirmBuy(q, postApiRes)
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData11()
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    handleShow();
                  }
                } else {
                  toast.error("Sale is Currently Paused");
                  setLoarder(false);
                }
              }
              else {
                setLoarder(false);
              }
            }
          } catch (err) {
            console.log(err, "errr");
            setLoarder(false);
            alowence();
            toast.error("Transaction reverted:" + err.message);
          }
        }
      }
    }


  };




  useEffect(() => {
    const interval = setInterval(() => {
      let now = ''
      let time = ''
      let diff = ''
      if (currentTime < new Date(parseInt(startTtime))) {
        now = new Date();
        time = new Date(parseInt(startTtime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
      } else if (currentTime > new Date(parseInt(startTtime)) && currentTime < new Date(parseInt(eendTime))) {
        now = new Date();
        time = new Date(parseInt(eendTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false)
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(startTtime))) {
          window.location.reload()
        }
        clearInterval(interval);
        setTimeshow(true)
        // setPrice(0)
        // setId(null)
        // setRound(null)
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / (60));
      const secs = Math.floor((diff % (60)));
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [eendTime, startTime]);

  const userLogin1 = async (e) => {
    // setLoarder(true);
    discoundedPriceee(e, Environment?.eth)
    let b = tab === 'home' ? "sale-rounds/users" : "sale-rounds/users-nft"
    await axios
      .get(API_URL + b)
      .then(async (res) => {
        // setLoarder(false);
        let a = res.data.data
        // console.log(a, 'aaaa', e);
        a.map((i) => {
          if (e == i?.round) {
            setNicName(i.nickName)

          }
        })

        setTimeout(() => {
          // console.log("Delayed for 1 second.");
          setLoarder1(false)
        }, "1000");
        // calucation(res.data.data)
        // console.log(res.data.data,'res.data.data');
      })
      .catch((err) => {
        console.log(err, "err");
        setLoarder1(false)
        // setLoarder(false);
      });
  };
  const calucation = (e) => {
    // console.log(e,'imad');
    let dummy = []
    let a = ''
    let indexx = ''
    let aaa = e.map((i, index) => {
      let tempLockedLending2 = ''
      if (currentTime > parseInt(i.startTime) && currentTime < parseInt(i.endTime)) {
        a = i
        indexx = index + 1
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)

      } else {
        let deff = currentTime - parseInt(i.startTime)
        tempLockedLending2 = { ...i, diffrence: deff, currentround: index + 1 };
        dummy.push(tempLockedLending2)
      }

    })
    if (a) {
      // console.log('noooooooor', a);
      setPrice(a?.price / 10 ** 18)
      setCopyOfPrice(a?.price / 10 ** 18)
      setCurrentRount(a)
      setCurrentRoundd(indexx)
      userLogin1(indexx)
      // setPrice(value?.price/10**18)

    }
    else if (dummy?.length > 0) {
      dummy.sort((a, b) => {
        return a?.diffrence - b?.diffrence
      })
      // console.log(dummy,'dummydummy');
      setCurrentRount(dummy[0])
      setCurrentRoundd(dummy[0]?.currentround)
      // userLogin1(dummy[0]?.currentround)
      setPrice(dummy[0]?.price / 10 ** 18)
      setCopyOfPrice(dummy[0]?.price / 10 ** 18)
      userLogin1(dummy[0]?.currentround)
      // console.log(dummy,'dummy');
    }
    // console.log(dummy,'new dateee',a);
    if (role === 'institution') {
      setPrice(instiPrice)
    }
  }
  // useEffect(() => {
  // console.log('usefffef',email,code);
  //   if (!email || !code) {
  //     history.push('/')
  //   }
  // }, [])
  // useEffect(() => {
  //   //  console.log(chainId,'chainIdchainId');
  //   if (account) {
  //     if (chainId != 1) {
  //       setupNetwork()
  //     }
  //   }
  // }, [account, chainId])
  // console.log(ethPricevalue,'ethPricevalue');



  const roundData = async (e) => {
    // console.log(e, 'eee');
    try {
      if (parseInt(e) <= 1) {
        // console.log('in if noor ', e);
        let c = ''
        if (tab === 'home') {
          // console.log(e,'noooor');
          c = await timer(e)
          // console.log(c,'in timer');
        } else {
          c = await timerNft(e)
        }

        if (c) {
          // console.log(e, 'ccc');
          setCurrentRount(c)
          setPrice(c?.price / 10 ** 18)
          setCopyOfPrice(c?.price / 10 ** 18)
          setCurrentRoundd(e)
          setTimeout(() => {
            // console.log("Delayed for 1 second.");
            setLoarder1(false)
          }, "1000");

          // setPrice(c.price/10**18)
          // console.log('nooor',c.price);
          // setCurrentTimer(c)
        }
      }
      else {
        let dummy = []
        // console.log(e, 'loooop bahis');
        for (let a = 1; a <= e; a++) {
          // console.log(a, 'loooop');
          let c = ''
          if (tab === 'home') {
            c = await timer(a)
            //  console.log('noor if  ',c);
          } else {
            // console.log('noor else  ',c);
            c = await timerNft(a)
          }
          dummy.push(c)

        }
        // console.log(dummy, 'loooop');
        calucation(dummy)


      }
    } catch (err) {
      console.log(err, 'erro in timer');
      setLoarder1(false)
    }
  }

  const inputField1Handler5 = async (e) => {
    var length = e.target.value.split(" ").join("").length;
    setInputField5(e.target.value)
    setCheck(false)
    if (length === 42) {
      let r = web3.utils.isAddress(e.target.value)
      if (r) {
        setCheck(true)

      } else {
        setCheck(false)
      }
    }
  }


  const confirmInst = async (data, api) => {
    // console.log(data, api, inputFeild5, price, inputFeild1, inputFeild, mainTag, 'noooooor');
    const config = {
      method: "post",
      url: `${API_URL}emails`,
      data: {
        "accessCode": code,
        "transactionHash": data?.transactionHash,
        "accessCodeRole": role,
        "by": account,
        "recipient": inputFeild5,
        "dopPurchased": inputFeild1,
        "price": price,
        "buyingAmount": inputFeild,
        "recipientEmail": email,
        "tokenSelected": mainTag,
      },

    };
    const postApiRes = await axios(config)
      .then(function (response) {
        // return response?.data?.data;
      })
      .catch(function (error) {
        // toast.error(error?.response?.data.message);
      });

  }
  const confirmBuy = async (data, api) => {
    // console.log(data, api, inputFeild5, price, inputFeild1, inputFeild, mainTag, 'noooooor');
    // const config = {
    //   method: "post",
    //   url: `${API_URL1}emails`,
    //   data: {
    //     "accessCode": code,
    //     "transactionHash": data?.transactionHash,
    //     "accessCodeRole": role,
    //     "by": account,
    //     "dopPurchased": inputFeild1,
    //     "price": price,
    //     "buyingAmount": inputFeild,
    //     "recipientEmail": email,
    //     "tokenSelected": mainTag,
    //   },

    // };
    // const postApiRes = await axios(config)
    //   .then(function (response) {
    //     // return response?.data?.data;
    //   })
    //   .catch(function (error) {
    //     // toast.error(error?.response?.data.message);
    //   });

  }


  const tabHandler = (e) => {
    discoundedPriceee(currentRoundd, Environment.eth)
    setMainTag("ETH");
    setTagImge('/assets/eth-icon.svg')
    if (tab == 'home') {
      setTab('profile')
    } else {
      setTab('home')
    }
  }
  // console.log(price,'buy price');
  const discoundedPriceee = async (aa, e) => {
    // console.log(a,e,'a,e');
    try {
      let ab = await discountedPrice(aa, e)
      // console.log('buy',ab,copyOfPrice);
      if (role != 'institution') {
        if (ab > 0) {
          // console.log('if chala');
          setPrice(ab)
          setPercet(100 * ((copyOfPrice - ab) / copyOfPrice))
          setPriceCheck(true)

        } else {
          if (copyOfPrice) {
            setPriceCheck(false)
            setPrice(copyOfPrice)
          }
        }
      }

    } catch (err) {
      console.log(err, 'discoundedPrice err');
    }
  }



  const [showerror, setShowerror] = useState(false);

  const handleCloseerror = () => setShowerror(false);
  const handleShowerror = () => setShowerror(true);




  //   useEffect(()=>{
  //     if(account){
  //       stepTwoactive()
  //     }
  //      else{

  //  history.push("/");
  //     }
  //   },[account])
  const stepTwoactive = async () => {
    // setLoarder1(true)
    // if (account){
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}testnet/buying-limit?walletAddress=${account?.toLowerCase()}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    axios.request(config)
      .then((response) => {
        // console.log(response.data,'response');
        // localStorage.setItem('buyCheck',true)
        // setCheck(true)

        setMaxPrice1(parseFloat(response.data.data[0].reward))
        // setMaxPrice2(response.data.data[0].reward-response.data.data[0].totalPurchased)
        // setLoarder1(false)


      })
      .catch((error) => {
        console.log(error);
        history.push("/eligible");
        // setLoarder1(false)

      });
    // } 

  }

  useEffect(()=>{
    if(!timerrr){
      history.push("/");
    }
  },[])
  useEffect(()=>{
    if(!modalll){
      setShow22(true);
    }
  },[])
  const submitt=()=>{
    localStorage.setItem("modalll",true)
    setShow22(false);
    // window.location.reload()
  }
  return (
    <>
      {loaderr1 && (
        <Loader />
      )}
      {loaderr && (
        <Loader text={"Transaction in progress"} />
      )}
      <Navbar setShow={setShow} />
      <section className="main-banner">
        <div className="container">
          <div className="banner-content">
            <div className="timermain">

              <div className="timerleft">
                <div className="mainiconimg">
                  <img src="\assets\tominet-icon.svg" alt="iconimginner" className="iconimginner" />
                </div>
                <div className="mainicontexts">
                  <h6 className="timerhead">DOP</h6>
                  {role !== "institution" ?
                    <>
                      {currentTime < new Date(parseInt(startTtime)) ?

                        <p className="timerpara">  Starts in:</p>
                        // <p className="timerpara">Testnet Round</p>
                        :
                        <>
                          {
                            currentTime > new Date(parseInt(startTtime)) && currentTime < new Date(parseInt(eendTime)) ?


                              <p className="timerpara">  Ends in:</p>
                              // <p className="timerpara">Testnet Round</p>
                              :
                              ""

                          }

                        </>

                      }

                    </>
                    :
                    ""
                  }
                </div>
              </div>
              {role !== "institution" ?
                <>
                  {timeshow ? (
                    <p className="timerended">Sale ended!</p>
                  ) : (
                    <div className="timerspans">
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{day ? day : 0}</span>
                        </div>
                        <p className="innertimepara">Days</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{hour ? hour : 0}</span>
                        </div>
                        <p className="innertimepara">Hours</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{min ? min : 0}</span>
                        </div>
                        <p className="innertimepara">Minutes</p>
                      </div>
                      <div className="maintime">
                        <div className="innertimer">
                          <span className="innertimertext">{sec ? sec : 0}</span>
                        </div>
                        <p className="innertimepara">Second</p>
                      </div>
                    </div>
                  )}
                </>
                :
                ""
              }

            </div>

            {/* <div className="upper-content">
              <div className="left">
                <img
                  src="\assets\tominet-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
                <div className="text">
                  <h6>DOP</h6>
                  <p>DOP</p>
                </div>
              </div>
              <div className="right">
                <h6>${tomi?.toFixed(2)}</h6>
                {change24hr1 > 0 ? (
                  <p>{change24hr1?.toFixed(2)}% (1d)</p>
                ) : (
                  <p className="red">{change24hr1?.toFixed(2)}% (1d)</p>
                )}
                {
                  priceCheck ?
                    <div className="right-text-content">
                      <div className="inner-left">
                        <h5>Special offer</h5>
                        <p className="special-para">You save {parseInt(percent)}%</p>
                      </div>
                      <div className="inner-right">
                        <h6> <h6>${price ? parseFloat(price)?.toFixed(4) : "0.000"} </h6></h6>
                        <p>${parseFloat(copyOfPrice).toFixed(4)}</p>
                      </div>
                    </div>
                    :
                    <h6>${price ? parseFloat(price)?.toFixed(4) : "0.000"} </h6>
                }


              </div>
            </div> */}
            <div className="maincontent">
              <div className="maininnercontent">
                <div className="contentleft">
                  <div className="contentimg">
                    <img src="\assets\newdoplogo.png" alt="innercontentimg" className="innercontentimg" />
                  </div>
                  <div className="contentexts">
                    <h6 className="contenthead">DOP</h6>
                    <p className="contentpara">DOP</p>
                  </div>
                </div>
                <div className="contentright">
                  <p className="contentrightpara">Price</p>
                  <h6 className="contentrighthead">${price?.toFixed(4)} USDT</h6>
                </div>
              </div>
              <div className="maininnercontent">
                <div>
                <p className="buyingpara">Buying Limit:</p>
                <h5 className="buyinglimitpara">{maxPrice?.toFixed(4)}/<span className="dulltext">{maxPrice1?.toFixed(4)} ETH</span></h5>
                </div>
              </div>
            </div>
            {role === 'institution' ?


              <div className="bottom-content">
                <h6 className="main-head">Buy</h6>
                <div className="parent-box">
                  <img
                    src="\assets\swap-arrow.svg"
                    alt="img"
                    className="img-fluid swap-arrow"
                  />
                  <div className="inner-box">  <div className="left">
                    {/* <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={tagImge}
                          alt="img"
                          className="img-fluid suxvsvsyucsusc"
                        />
                        <div className="text">
                          <h6>{mainTag}</h6>
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')}>
                            <img
                              src="/assets/eth-icon.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            ETH
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')}>
                            <img
                              src="/assets/usdt.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            USDT
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('TOMI')}>
                            <img
                              src="\assets\tomi-icon-drop.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            TOMI
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    <div className="buyeth">
                      <div className="buyethimg">
                        <img src="\assets\ethereum-eth.svg" alt="buyethinnerimg" className="buyethinnerimg" />
                      </div>
                      <p className="buyethpara">ETH</p>
                    </div>
                  </div>
                    <div className="right">
                      <h6>
                        Balance:{" "}
                        <span>
                          {mainTag === "ETH"
                            ? blanceOfEth > 0
                              ? blanceOfEth
                                ?.toFixed(4)
                              : "0.000"

                            :
                            mainTag === "USDT" ?
                              balanceeUsdt > 0
                                ? balanceeUsdt
                                  ?.toFixed(4)
                                : "0.000"
                              :
                              balaceOfTmi > 0
                                ? balaceOfTmi
                                  ?.toFixed(4)
                                : "0.000"
                          }{" "}
                          {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " : "TOMI"}{" "}
                        </span>{" "}
                      </h6>
                      {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                      <input
                        onChange={inputField1Handler}
                        value={inputFeild}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>
                  <div className="inner-box">
                    <div className="leftiii">
                      <img
                        src="\assets\tomi-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <div className="text">
                        <h6>DOP</h6>
                      </div>
                    </div>
                    <div className="right">
                      <h6>
                        Balance:{" "}
                        {/* <span style={{ color: "#fff" }}>
          {balacefAccountTomii > 0
            ? balacefAccountTomii
              ?.toFixed(4)
            : "0.000"}
        </span> */}
                      </h6>
                      <input
                        onChange={inputField1Handler1}
                        value={inputFeild1}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>

                </div>
                {role === 'institution' &&
                  <div className="material-textfield" style={{ marginTop: "5px", marginBottom: "25px" }}>
                    <input
                      onChange={inputField1Handler5}
                      value={inputFeild5}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      placeholder="Enter only ERC20 wallet address"


                    />
                    <label>Enter DOP recipient wallet address</label>
                  </div>
                }
                <div className="bottom-text">
                  {/* <div className="inner-text">
<p>Receive:</p>
<h6>
{inputFeild1
  ? inputFeild1
    ?.toFixed(3)
  : "0.00"}{" "}
TOMI
</h6>
</div>
<div className="inner-text">
<p>+{aaa / 10}% Bonus:</p>
<h6>
{inputFeild1
  ? ((inputFeild1 * (aaa / 10)) / 100)
    ?.toFixed(3)
  : "0.00"}{" "}
TOMI
</h6>
</div> */}
                  <div className="inner-text-bold">
                    <h6>Total:</h6>
                    <h6>
                      {inputFeild1 && inputFeild
                        ? parseFloat((inputFeild1))
                          ?.toFixed(3)
                        : "0.00"}{" "}
                      DOP
                    </h6>
                  </div>
                  <div className="inner-text">
                    <p>Expected Unlock Date:</p>
                    {/* <h6>{date}</h6> */}
                    <h6>Q1,2024</h6>
                  </div>
                </div>
                {account ? (

                  <>
                    {role !== "institution" ?
                      <>
                        {mainTag === "ETH" ? (
                          <>
                            {inputFeild == "" ||
                              inputFeild == null || inputFeild <= 0 ||
                              blanceOfEth < inputFeild ? (
                              <>
                                <button className=" btn-buy">Buy DOP </button>
                              </>
                            ) : (
                              <button className="newClasss" onClick={handlerPop}>
                                Buy DOP{" "}
                              </button>
                            )}
                          </>
                        ) :
                          <>
                            {mainTag === "USDT" ?
                              (
                                <>
                                  {inputFeild == "" ||
                                    inputFeild == null || inputFeild <= 0 ||
                                    balanceeUsdt < inputFeild ? (
                                    <>
                                      <button className=" btn-buy">Buy DOP </button>
                                    </>
                                  ) : (
                                    <button className="newClasss" onClick={handlerPop}>
                                      Buy DOP{" "}
                                    </button>
                                  )}
                                </>
                              )
                              :
                              (
                                <>
                                  {inputFeild == "" ||
                                    inputFeild == null || inputFeild <= 0 ||
                                    balancerOftomi < inputFeild ? (
                                    <>
                                      <button className=" btn-buy">Buy DOP </button>
                                    </>
                                  ) : (
                                    <button className="newClasss" onClick={handlerPop}>
                                      Buy DOP{" "}
                                    </button>
                                  )}
                                </>
                              )


                            }
                          </>}
                      </>
                      :
                      <>
                        {mainTag === "ETH" ? (
                          <>
                            {check ? <>
                              {inputFeild == "" ||
                                inputFeild == null || inputFeild <= 0 ||
                                blanceOfEth < inputFeild ? (
                                <>
                                  <button className=" btn-buy">Buy DOP{check}</button>
                                </>
                              ) : (
                                <button className="newClasss" onClick={handlerPop}>
                                  Buy DOP{" "}
                                </button>
                              )}
                            </>
                              :
                              <button className=" btn-buy">Buy DOP</button>
                            }

                          </>
                        ) :
                          <>
                            {mainTag === "USDT" ?
                              (
                                <>
                                  {check ? <>
                                    {inputFeild == "" ||
                                      inputFeild == null || inputFeild <= 0 ||
                                      balanceeUsdt < inputFeild ? (
                                      <>
                                        <button className=" btn-buy">Buy DOP{check}</button>
                                      </>
                                    ) : (
                                      <button className="newClasss" onClick={handlerPop}>
                                        Buy DOP{" "}
                                      </button>
                                    )}
                                  </>
                                    :
                                    <button className=" btn-buy">Buy DOP</button>
                                  }

                                </>
                              )
                              :
                              (
                                <>
                                  {check ? <>
                                    {inputFeild == "" ||
                                      inputFeild == null || inputFeild <= 0 ||
                                      balaceOfTmi < inputFeild ? (
                                      <>
                                        <button className=" btn-buy">Buy DOP{check}</button>
                                      </>
                                    ) : (
                                      <button className="newClasss" onClick={handlerPop}>
                                        Buy DOP{" "}
                                      </button>
                                    )}
                                  </>
                                    :
                                    <button className=" btn-buy">Buy DOP</button>
                                  }

                                </>
                              )

                            }
                          </>

                        }
                      </>
                      // <button className=" newClasss" onClick={handlerPop}>Buy DOP </button>

                    }
                  </>
                ) : (
                  <button className="btn-buy" onClick={() => setShow(true)}>
                    Buy DOP
                  </button>
                )}
              </div>


              :

              //             <div className="custom-tabs">
              //               <Tabs
              //                 defaultActiveKey={tab}
              //                 id="uncontrolled-tab-example"
              //                 onSelect={tabHandler}>
              //                 <Tab eventKey='home' title="Buy $DOP">
              //                   <div className="bottom-content">
              //                     <h6 className="main-head">Buy</h6>
              //                     <div className="parent-box">
              //                       <img
              //                         src="\assets\swap-arrow.svg"
              //                         alt="img"
              //                         className="img-fluid swap-arrow"
              //                       />
              //                       <div className="inner-box">  <div className="left buyjustDOP">
              //                         <div class="dropdown">
              //                           <button
              //                             class="dropdown-toggle"
              //                             type="button"
              //                             data-toggle="dropdown"
              //                             aria-expanded="false"
              //                           >
              //                             <img
              //                               src={tagImge}
              //                               alt="img"
              //                               className="img-fluid suxvsvsyucsusc"
              //                               style={{width: "26px", height: "26px", objectFit: "cover"}}
              //                             />
              //                             <div className="text">
              //                               <h6>{mainTag}</h6>
              //                             </div>
              //                           </button>
              //                           <ul class="dropdown-menu">
              //                             <li>
              //                               <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')}>
              //                                 <img
              //                                   src="/assets/eth-icon.svg"
              //                                   alt="img"
              //                                   className="img-fluid"
              //                                 />{" "}
              //                                 ETH
              //                               </a>
              //                             </li>
              //                             <li>
              //                               <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')}>
              //                                 <img
              //                                   src="/assets/usdt.svg"
              //                                   alt="img"
              //                                   className="img-fluid"
              //                                 />{" "}
              //                                 USDT
              //                               </a>
              //                             </li>
              //                             {/* <li>
              //                               <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('TOMI')}>
              //                                 <img
              //                                   src="\assets\tomi-icon-drop.svg"
              //                                   alt="img"
              //                                   className="img-fluid"
              //                                 />{" "}
              //                                 TOMI
              //                               </a>
              //                             </li> */}
              //                             <li>
              //                               <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDC')}>
              //                                 <img
              //                                   src="\assets\usdc.svg"
              //                                   alt="img"
              //                                   className="img-fluid"
              //                                 />{" "}
              //                                 USDC
              //                               </a>
              //                             </li>
              //                             <li>
              //                               <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('WBTC')}>
              //                                 <img
              //                                   src="\assets\wbtc.svg"
              //                                   alt="img"
              //                                   className="img-fluid"
              //                                 />{" "}
              //                                 WBTC
              //                               </a>
              //                             </li>
              //                           </ul>
              //                         </div>
              //                       </div>
              //                         <div className="right">
              //                           <h6>
              //                             Balance:{" "}
              //                             <span>
              //                               {mainTag === "ETH"
              //                                 ? blanceOfEth > 0
              //                                   ? blanceOfEth
              //                                     ?.toFixed(4)
              //                                   : "0.000"

              //                                 :
              //                                 mainTag === "USDT" ?
              //                                   balanceeUsdt > 0
              //                                     ? balanceeUsdt
              //                                       ?.toFixed(4)
              //                                     : "0.000"
              //                                   :
              //                                   mainTag === "USDC" ?
              //                                   balaceOfUsdc > 0
              //                                     ? balaceOfUsdc
              //                                       ?.toFixed(4)
              //                                     : "0.000"
              //                                   :
              //                                   mainTag === "WBTC" ?
              //                                   balaceOfWbtc > 0
              //                                     ? balaceOfWbtc
              //                                       ?.toFixed(6)
              //                                     : "0.000"
              //                                   :

              //                                   balaceOfTmi > 0
              //                                     ? balaceOfTmi
              //                                       ?.toFixed(4)
              //                                     : "0.000"
              //                               }{" "}
              //                               {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " :mainTag === "USDC"?"USDC":mainTag === "WBTC"?"WBTC" :"TOMI"}{" "}
              //                             </span>{" "}
              //                           </h6>
              //                           {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
              //                           <input
              //                             onChange={inputField1Handler}
              //                             value={inputFeild}
              //                             onWheel={(e) => e.target.blur()}
              //                             type="number"
              //                             placeholder="0.00"
              //                           />
              //                           {/* <p>0.00</p> */}
              //                         </div>
              //                       </div>
              //                       <div className="inner-box">
              //                         <div className="leftiii">
              //                           <img
              //                             src="\assets\tomi-icon.svg"
              //                             alt="img"
              //                             className="img-fluid"
              //                           />
              //                           <div className="text">
              //                             <h6>DOP</h6>
              //                           </div>
              //                         </div>
              //                         <div className="right">
              //                           <h6>
              //                             Balance:{" "}
              //                             {/* <span style={{ color: "#fff" }}>
              //         {balacefAccountTomii > 0
              //           ? balacefAccountTomii
              //             ?.toFixed(4)
              //           : "0.000"}
              //       </span> */}
              //                           </h6>
              //                           <input
              //                             onChange={inputField1Handler1}
              //                             value={inputFeild1}
              //                             onWheel={(e) => e.target.blur()}
              //                             type="number"
              //                             placeholder="0.00"
              //                           />
              //                           {/* <p>0.00</p> */}
              //                         </div>
              //                       </div>

              //                     </div>
              //                     {role === 'institution' &&
              //                       <div className="material-textfield" style={{ marginTop: "5px", marginBottom: "25px" }}>
              //                         <input
              //                           onChange={inputField1Handler5}
              //                           value={inputFeild5}
              //                           onWheel={(e) => e.target.blur()}
              //                           type="text"
              //                           placeholder="Enter only ERC20 wallet address"


              //                         />
              //                         <label>Enter DOP recipient wallet address</label>
              //                       </div>
              //                     }
              //                     <div className="bottom-text">
              //                       {/* <div className="inner-text">
              //   <p>Receive:</p>
              //   <h6>
              //     {inputFeild1
              //       ? inputFeild1
              //         ?.toFixed(3)
              //       : "0.00"}{" "}
              //     TOMI
              //   </h6>
              // </div>
              // <div className="inner-text">
              //   <p>+{aaa / 10}% Bonus:</p>
              //   <h6>
              //     {inputFeild1
              //       ? ((inputFeild1 * (aaa / 10)) / 100)
              //         ?.toFixed(3)
              //       : "0.00"}{" "}
              //     TOMI
              //   </h6>
              // </div> */}
              //                       <div className="inner-text-bold">
              //                         <h6>Total:</h6>
              //                         <h6>
              //                           {inputFeild1 && inputFeild
              //                             ? parseFloat((inputFeild1))
              //                               ?.toFixed(3)
              //                             : "0.00"}{" "}
              //                           DOP
              //                         </h6>
              //                       </div>
              //                       <div className="inner-text">
              //                         <p>Unlock Date:</p>
              //                         {/* <h6>{date}</h6> */}
              //                         <h6>Q1,2024</h6>
              //                       </div>
              //                     </div>
              //                     {account ? (

              //                       <>
              //                         {role !== "institution" ?
              //                           <>
              //                             {mainTag === "ETH" ? (
              //                               <>
              //                                 {inputFeild == "" ||
              //                                   inputFeild == null || inputFeild <= 0 ||
              //                                   blanceOfEth < inputFeild ? (
              //                                   <>
              //                                     <button className=" btn-buy">Buy DOP </button>
              //                                   </>
              //                                 ) : (
              //                                   <button className="newClasss" onClick={handlerPop}>
              //                                     Buy DOP{" "}
              //                                   </button>
              //                                 )}
              //                               </>
              //                             ) :
              //                               <>
              //                                 {mainTag == "USDT" ?
              //                                   (
              //                                     // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              //                                     <>
              //                                       {inputFeild == "" ||
              //                                         inputFeild == null || inputFeild <= 0 ||
              //                                         balanceeUsdt < inputFeild ? (
              //                                         <button className=" btn-buy">Buy DOP</button>
              //                                       ) : (
              //                                         <button className="newClasss" onClick={handlerPop}>
              //                                           Buy DOP{" "}
              //                                         </button>
              //                                       )}
              //                                     </>
              //                                   )
              //                                   :
              //                                   <>
              //                                   {
              //                                     mainTag == "USDC" ?
              //                                     (
              //                                       // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              //                                       <>
              //                                         {inputFeild == "" ||
              //                                           inputFeild == null || inputFeild <= 0 ||
              //                                           balaceOfUsdc < inputFeild ? (
              //                                           <button className=" btn-buy">Buy DOP</button>
              //                                         ) : (
              //                                           <button className="newClasss" onClick={handlerPop}>
              //                                             Buy DOP{" "}
              //                                           </button>
              //                                         )}
              //                                       </>
              //                                     )
              //                                     :
              //                                     <>
              //                                     {mainTag=="WBTC"?
              //                                       (
              //                                         // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              //                                         <>
              //                                           {inputFeild == "" ||
              //                                             inputFeild == null || inputFeild <= 0 ||
              //                                             balaceOfWbtc < inputFeild ? (
              //                                             <button className=" btn-buy">Buy DOP</button>
              //                                           ) : (
              //                                             <button className="newClasss" onClick={handlerPop}>
              //                                               Buy DOP{" "}
              //                                             </button>
              //                                           )}
              //                                         </>
              //                                       )
              //                                     :
              //                                     (
              //                                       // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              //                                       <>
              //                                         {inputFeild == "" ||
              //                                           inputFeild == null || inputFeild <= 0 ||
              //                                           balaceOfTmi < inputFeild ? (
              //                                           <button className=" btn-buy">Buy DOP</button>
              //                                         ) : (
              //                                           <button className="newClasss" onClick={handlerPop}>
              //                                             Buy DOP{" "}
              //                                           </button>
              //                                         )}
              //                                       </>
              //                                     )

              //                                     }
              //                                     </>

              //                                   }
              //                                   </>
              //                                 }
              //                               </>}
              //                           </>
              //                           :
              //                           <>
              //                             {mainTag === "ETH" ? (
              //                               <>
              //                                 {check ? <>
              //                                   {inputFeild == "" ||
              //                                     inputFeild == null || inputFeild <= 0 ||
              //                                     blanceOfEth < inputFeild ? (
              //                                     <>
              //                                       <button className=" btn-buy">Buy DOP{check}</button>
              //                                     </>
              //                                   ) : (
              //                                     <button className="newClasss" onClick={handlerPop}>
              //                                       Buy DOP{" "}
              //                                     </button>
              //                                   )}
              //                                 </>
              //                                   :
              //                                   <button className=" btn-buy">Buy DOP</button>
              //                                 }

              //                               </>
              //                             ) : (
              //                               // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              //                               <>
              //                                 {check ? <>
              //                                   {inputFeild == "" ||
              //                                     inputFeild == null || inputFeild <= 0 ||
              //                                     balanceeUsdt < inputFeild ? (
              //                                     <>
              //                                       <button className=" btn-buy">Buy DOP</button>
              //                                     </>
              //                                   ) : (
              //                                     <button className="newClasss" onClick={handlerPop}>
              //                                       Buy DOP{" "}
              //                                     </button>
              //                                   )}
              //                                 </>
              //                                   :
              //                                   <button className=" btn-buy">Buy DOP</button>
              //                                 }
              //                               </>
              //                             )}
              //                           </>
              //                           // <button className=" newClasss" onClick={handlerPop}>Buy DOP </button>

              //                         }
              //                       </>
              //                     ) : (
              //                       <button className="btn-buy" onClick={() => setShow(true)}>
              //                         Buy DOP
              //                       </button>
              //                     )}
              //                   </div>
              //                 </Tab>
              //                 <Tab eventKey='profile' title="Buy DOP NFTs">
              //                   <DOPNft balaceOfWbtc={balaceOfWbtc} balaceOfUsdc={balaceOfUsdc}  getAllowanceWbtc={getAllowanceWbtc} getAllowanceUsdc={getAllowanceUsdc} setShow={setShow} price={price} wbtcCurrentPrice={wbtcCurrentPrice}  ethPricevalue={ethPricevalue} code={code} setLoarder={setLoarder} saleCurrentStaus={saleCurrentStaus} currentRoundd={currentRoundd} blanceOfEth={blanceOfEth} balanceeUsdt={balanceeUsdt} tomiPrice={tomiPrice} tomiAllowance={tomiAllowance} allowanceTpl={allowanceTpl} balaceOfTmi={balaceOfTmi} alowence={alowence} sallowance={sallowance} discoundedPriceee={discoundedPriceee} tab={tab} />
              //                 </Tab>
              //               </Tabs>
              //             </div>

              <div className="bottom-content">
                <h6 className="main-head">Buy</h6>
                <div className="parent-box">
                  <img
                    src="\assets\swap-arrow.svg"
                    alt="img"
                    className="img-fluid swap-arrow"
                  />
                  <div className="inner-box">  <div className="left">
                    {/* <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={tagImge}
                          alt="img"
                          className="img-fluid suxvsvsyucsusc"
                        />
                        <div className="text">
                          <h6>{mainTag}</h6>
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('ETH')}>
                            <img
                              src="/assets/eth-icon.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            ETH
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDT')}>
                            <img
                              src="/assets/usdt.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            USDT
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('TOMI')}>
                            <img
                              src="\assets\tomi-icon-drop.svg"
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            TOMI
                          </a>
                        </li>
                        <li>
                                          <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('USDC')}>
                                        <img
                                              src="\assets\usdc.svg"
                                         alt="img"
                                            className="img-fluid"
                                             />{" "}
                                          USDC
                                            </a>
                                     </li>
                                         <li>
                                             <a class="dropdown-item gscrxcsx" onClick={() => changeTag1('WBTC')}>
                                               <img
                                                 src="\assets\wbtc.svg"
                                                 alt="img"
                                                 className="img-fluid"
                                               />{" "}
                                               WBTC
                                             </a>
                                           </li>
                      </ul>
                    </div> */}
                              <div className="buyeth">
                      <div className="buyethimg">
                        <img src="\assets\ethereum-eth.svg" alt="buyethinnerimg" className="buyethinnerimg" />
                      </div>
                      <p className="buyethpara">ETH</p>
                    </div>
                  </div>
                    <div className="right">
                      <h6>
                        Balance:{" "}
                        <span>
                        {mainTag === "ETH"
                                                ? blanceOfEth > 0
                                                  ? blanceOfEth
                                                    ?.toFixed(4)
                                                  : "0.000"

                                                :
                                                mainTag === "USDT" ?
                                                  balanceeUsdt > 0
                                                    ? balanceeUsdt
                                                      ?.toFixed(4)
                                                    : "0.000"
                                                  :
                                                  mainTag === "USDC" ?
                                                  balaceOfUsdc > 0
                                                    ? balaceOfUsdc
                                                      ?.toFixed(4)
                                                    : "0.000"
                                                  :
                                                  mainTag === "WBTC" ?
                                                  balaceOfWbtc > 0
                                                    ? balaceOfWbtc
                                                      ?.toFixed(6)
                                                    : "0.000"
                                                  :

                                                  balaceOfTmi > 0
                                                    ? balaceOfTmi
                                                      ?.toFixed(4)
                                                    : "0.000"
                                              }{" "}
                          {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " :mainTag === "USDC"?"USDC":mainTag === "WBTC"?"WBTC" :"TOMI"}
                        </span>{" "}
                      </h6>
                      {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                      <input
                        onChange={inputField1Handler}
                        value={inputFeild}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>
                  <div className="inner-box">
                    <div className="leftiii">
                      <img
                        src="\assets\tomi-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <div className="text">
                        <h6>DOP</h6>
                      </div>
                    </div>
                    <div className="right">
                      <h6>
                        Balance:{" "}
                        {/* <span style={{ color: "#fff" }}>
{balacefAccountTomii > 0
? balacefAccountTomii
?.toFixed(4)
: "0.000"}
</span> */}
                      </h6>
                      <input
                        onChange={inputField1Handler1}
                        value={inputFeild1}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        placeholder="0.00"
                      />
                      {/* <p>0.00</p> */}
                    </div>
                  </div>

                </div>
                {role === 'institution' &&
                  <div className="material-textfield" style={{ marginTop: "5px", marginBottom: "25px" }}>
                    <input
                      onChange={inputField1Handler5}
                      value={inputFeild5}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      placeholder="Enter only ERC20 wallet address"


                    />
                    <label>Enter DOP recipient wallet address</label>
                  </div>
                }
                <div className="bottom-text">
                  {/* <div className="inner-text">
<p>Receive:</p>
<h6>
{inputFeild1
? inputFeild1
?.toFixed(3)
: "0.00"}{" "}
TOMI
</h6>
</div>
<div className="inner-text">
<p>+{aaa / 10}% Bonus:</p>
<h6>
{inputFeild1
? ((inputFeild1 * (aaa / 10)) / 100)
?.toFixed(3)
: "0.00"}{" "}
TOMI
</h6>
</div> */}
                  <div className="inner-text-bold">
                    <h6>Total:</h6>
                    <h6>
                      {inputFeild1 && inputFeild
                        ? parseFloat((inputFeild1))
                          ?.toFixed(3)
                        : "0.00"}{" "}
                      DOP
                    </h6>
                  </div>
                  <div className="inner-text">
                    <p>Expected Unlock Date:</p>
                    {/* <h6>{date}</h6> */}
                    <h6>Q1,2024</h6>
                  </div>
                </div>
                {account ? (

<>
  {role !== "institution" ?
    <>
      {mainTag === "ETH" ? (
        <>
          {inputFeild == "" ||
            inputFeild == null || inputFeild <= 0 ||
            blanceOfEth < inputFeild ||maxPrice2 < inputFeild||  inputFeild <0.1 ? (
            <>
              <button className=" btn-buy">Buy DOP </button>
            </>
          ) : (
            <button className="newClasss" onClick={handlerPop}>
              Buy DOP{" "}
            </button>
          )}
        </>
      ) :
        <>
          {mainTag == "USDT" ?
            (
              // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
              <>
                {inputFeild == "" ||
                  inputFeild == null || inputFeild <= 0 ||
                  balanceeUsdt < inputFeild ? (
                  <button className=" btn-buy">Buy DOP</button>
                ) : (
                  <button className="newClasss" onClick={handlerPop}>
                    Buy DOP{" "}
                  </button>
                )}
              </>
            )
            :
            <>
            {
              mainTag == "USDC" ?
              (
                // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                <>
                  {inputFeild == "" ||
                    inputFeild == null || inputFeild <= 0 ||
                    balaceOfUsdc < inputFeild ? (
                    <button className=" btn-buy">Buy DOP</button>
                  ) : (
                    <button className="newClasss" onClick={handlerPop}>
                      Buy DOP{" "}
                    </button>
                  )}
                </>
              )
              :
              <>
              {mainTag=="WBTC"?
                (
                  // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                  <>
                    {inputFeild == "" ||
                      inputFeild == null || inputFeild <= 0 ||
                      balaceOfWbtc < inputFeild ? (
                      <button className=" btn-buy">Buy DOP</button>
                    ) : (
                      <button className="newClasss" onClick={handlerPop}>
                        Buy DOP{" "}
                      </button>
                    )}
                  </>
                )
              :
              (
                // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                <>
                  {inputFeild == "" ||
                    inputFeild == null || inputFeild <= 0 ||
                    balaceOfTmi < inputFeild ? (
                    <button className=" btn-buy">Buy DOP</button>
                  ) : (
                    <button className="newClasss" onClick={handlerPop}>
                      Buy DOP{" "}
                    </button>
                  )}
                </>
              )

              }
              </>

            }
            </>
          }
        </>}
    </>
    :
    <>
      {mainTag === "ETH" ? (
        <>
          {check ? <>
            {inputFeild == "" ||
              inputFeild == null || inputFeild <= 0 ||
              blanceOfEth < inputFeild ? (
              <>
                <button className=" btn-buy">Buy DOP{check}</button>
              </>
            ) : (
              <button className="newClasss" onClick={handlerPop}>
                Buy DOP{" "}
              </button>
            )}
          </>
            :
            <button className=" btn-buy">Buy DOP</button>
          }

        </>
      ) : (
        // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
        <>
          {check ? <>
            {inputFeild == "" ||
              inputFeild == null || inputFeild <= 0 ||
              balanceeUsdt < inputFeild ? (
              <>
                <button className=" btn-buy">Buy DOP</button>
              </>
            ) : (
              <button className="newClasss" onClick={handlerPop}>
                Buy DOP{" "}
              </button>
            )}
          </>
            :
            <button className=" btn-buy">Buy DOP</button>
          }
        </>
      )}
    </>
    // <button className=" newClasss" onClick={handlerPop}>Buy DOP </button>

  }
</>
) : (
<button className="btn-buy" onClick={() => setShow(true)}>
  Buy DOP
</button>
)}
               
              </div>

            }

          </div>
        </div>
      </section>
      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>DOP Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\purchased.svg" alt="img" className="img-fluid" />
            <p>
              You have successfully purchased{" "} <br />
              <span>
                {inputFeild1
                  ? parseFloat(inputFeild1)
                    ?.toFixed(3)
                  : "0.00"}{" "}
                DOP </span> for     <span> {inputFeild} {mainTag === "ETH" ? " ETH " : mainTag === "USDT" ? " USDT " : mainTag === "TOMI" ? "TOMI" : mainTag === "USDC" ? 'USDC' : "WBTC"} </span>
              {/* You can claim your DOP on <span>Q1,2024</span> */}
            </p>
            <button className="btn-common" onClick={handleClose}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <button onClick={handleShowerror}>dsf</button> */}
      <Modal
        className="connectwallet-modal buy-modal"
        show={showerror}
        onHide={handleCloseerror}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\newtryagain.png" alt="img" className="img-fluid" />
            <p>
              Oops, something went wrong
            </p>
            <button className="btn-common" onClick={handleCloseerror}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        className="connectwallet-modal"
        show={show22}
        onHide={handleClose22}
        centered
        backdrop="static" 
      >
        <Modal.Header
        >
          <Modal.Title><img src="\assets\warning.svg" alt="img" className="mr-2" />PRIVATE SALE AGREEMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="darktext">
            <div className="scrolltext">
              <p className="darkpara">This PRIVATE SALE AGREEMENT (the <span className="bold">“Agreement”</span>), entered into between yourself (the <span className="bold">“Purchaser”</span>) and
                Data Ownership Ltd., (or such other name to be approved by the applicable authorities) a company in formation
                (the <span className="bold">“Company”</span>) forms a binding legal agreement that governs the Purchaser’s purchase of the right to claim
                certain tokens intended to be issued by the Company, carrying the tentative name ‘DOP’ (the <span className="bold">“Tokens”</span> or
                <span className="bold"> “DOP”</span>) as part of the initial sale of the Tokens to private purchasers through the Company’s website at <a style={{ textDecoration: 'none', color: 'white' }} target="_blank" href="https://privatesale.dop.org/">privatesale.DOP.org</a> (the <span className="bold">“Website”</span>).</p>
              <p className="darkpara">By using the Website to purchase DOP Tokens and digitally signing and completing the DOP Tokens purchase
                transaction you confirm and acknowledge that you understand and accept the terms and conditions of this
                Agreement and you agree to be bound by them. The Company may amend, update or change this Agreement
                from time to time and without prior notice at its sole discretion, and each such amendment shall become
                automatically valid and enforceable at the time when first displayed by the Company in the Website. Therefore,
                please check this Agreement (which can be found on the Website) regularly for changes.</p>
              <p className="darkparaupper">1. Events</p>
              <p className="darkpara"><span className="headingtext formarginleft">a. Initial Launch.</span> If there is an Initial Launch before the expiration or termination of this instrument, the
                Company will issue to the Purchaser a number of DOP Tokens equal to the Purchase Amount divided by the
                Purchase Price.</p>
              <p className="darkpara"><span className="formarginleft">In</span> connection with, as a condition to, and prior to the issuance of the Tokens by the Company to the
                Purchaser pursuant to  this Section 1(a): (i) the Purchaser will execute and deliver to the Company  any and all
                other transaction documents related to this Agreement as are reasonably requested by the Company (if any),
                including verification of accredited investor status or non-U.S. person status under the applicable laws; and (ii)
                the Purchaser will provide to the Company a network address in which Purchaser's Tokens will be claimable (the
                <span className="bold">“Purchaser’s Digital Wallet”</span>).</p>
              <p className="darkpara"><span className="formarginleft">For</span> the avoidance of doubt, (i) the Tokens may be claimed by the Purchaser following at a date that
                will be determined at the Company’s sole discretion, following the consummation of the Token’s private and
                public sale; (ii) in order to actually receive any of the Tokens upon issuance thereof, the Purchaser will be
                required to claim the Tokens to the Purchaser’s Digital Wallet, as shall be instructed by the Company; in respect
                with such claim, third party fees (such as network and wallet fees) may be charged.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b. Dissolution Event.</span> If there is a Dissolution Event before this instrument expires or terminates, the
                Company will pay an amount equal to the Purchase Amount actually received from the Purchaser (the <span className="bold">“Returned
                  Purchase Amount”</span>), due and payable to the Purchaser immediately prior to, or concurrent with, the
                consummation of the Dissolution Event, to the extent funds are available and prior to paying any amounts to any
                equity holders of the Company. If immediately prior to the consummation of the Dissolution Event, the assets of
                the Company that remain legally available for distribution to the Purchaser and all other parties of Private Sale
                Agreement (the <span className="bold">“Dissolving Purchasers”</span>), as determined in good faith by the Company, are insufficient to
                permit the payment to the Dissolving Purchasers of their respective Returned Purchase Amounts, then the
                remaining assets of the Company legally available for distribution will be distributed with equal priority and pro
                rata among the Dissolving Purchasers in proportion to the Returned Purchase Amounts they would otherwise be
                entitled to receive pursuant to this Section 1(b). Any distributed amounts shall be in U.S. Dollars.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c. </span> The Purchase Amount shall be paid to the Company’s digital wallet in one of the following
                cryptocurrencies: ETH, USDT or USDC, at the address to be provided the Company to the Purchaser.</p>
              <p className="darkpara"><span className="formarginleft">The</span> receipt of the payment of the Purchase Amount shall be at the Purchaser’s sole responsibility. It is
                recommended to contact the Company’s representative and perform a low amount trial payment and receive the
                Company’s representative’s approval for such payment prior to the transmission of the full amount.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d. </span> For the avoidance of doubt, (i) other than as set forth  in this Agreement, Purchaser shall not be
                entitled to be issued with any tokens prior to the Effective Date; (ii) in no event shall the Purchaser shall be
                entitled to receive any refund, repayment or distribution on account of any or all of the Purchase Amount.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e. Termination.</span> This instrument will expire and terminate upon the earlier of (i) the issuance of Tokens
                to the Purchaser pursuant to Section 1(a); (ii) the payment, or setting aside for payment, of amounts due the
                Purchaser pursuant to Section 1(b); or (iii) December 31, 2024 (the <span className="bold">“Deadline Date”</span>), if the Initial Launch has
                not occurred as of such date; provided that, the Company shall have the right to extend the Deadline Date by sixty
                (60) days, in its sole discretion; provided, that in the case of (iii), the Company shall have the obligation to repay
                to the Purchasers the aggregate amount of all Purchase Amounts.This instrument will expire and terminate upon the earlier of (i) the issuance of Tokens
                to the Purchaser pursuant to Section 1(a); (ii) the payment, or setting aside for payment, of amounts due the
                Purchaser pursuant to Section 1(b); or (iii) December 31, 2024 (the “Deadline Date”), if the Initial Launch has
                not occurred as of such date; provided that, the Company shall have the right to extend the Deadline Date by sixty
                (60) days, in its sole discretion; provided, that in the case of (iii), the Company shall have the obligation to repay
                to the Purchasers the aggregate amount of all Purchase Amounts.</p>
              <p className="darkparaupper">2. Definitions.</p>
              <p className="darkpara"><span className="headingtext formarginleft">a. “Dissolution Event”</span> means (i) a voluntary termination of operations of the Company, (ii) a general
                assignment for the benefit of the Company’s creditors or (iii) any other liquidation, dissolution or winding up of
                the Company, whether voluntary or involuntary.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b. “Governmental Authority”</span> means any nation or government, any state or other political subdivision
                thereof, any entity exercising legislative, judicial or administrative functions of or pertaining to government,
                including, without limitation, any government authority, agency, department, board, commission or
                instrumentality, and any court, tribunal or arbitrator(s) of competent jurisdiction, and any self-regulatory
                organization.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c. “Initial Launch”</span> means the bona fide public release of DOP tokens to the public by the Company.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d. “Laws” </span> means laws, statutes, ordinances, rules, regulations, judgments, injunctions, orders and
                decrees.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e. “Person”</span> means individual or legal entity or person, including a government or political subdivision
                or an agency or instrumentality thereof.</p>
              <p className="darkpara"><span className="headingtext formarginleft">f. “Purchase Amount”</span> means the amount the Purchaser actually paid to the Company on account of
                purchased DOP Tokens, as shall be appeared in the Website and evidenced by the applicable transaction
                information in public blockchain scanners.</p>
              <p className="darkpara"><span className="headingtext formarginleft">g. “Purchase Price”</span> means the purchase price per token on which the Purchaser has purchased the DOP
                Tokens, as shall appear on the Website prior to the execution of the purchase transaction.</p>
              <p className="darkparaupper">3. Company Representations.</p>
              <p className="darkpara"><span className="headingtext formarginleft">a.</span> The Company is a company in formation; once the Company is incorporated in accordance with
                the laws of the applicable jurisdiction, the Company shall be a corporation duly organized, validly existing and in
                good standing under the laws of such jurisdiction, and shall have the power and authority to own, lease and
                operate its properties and carry on its business as expected to be conducted.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b.</span> The execution, delivery and performance by the Company of this instrument is, to the Company’s
                knowledge, within the power of the Company and, other than with respect to the actions to be taken when the
                Tokens are to be issued to the Purchaser, has been duly authorized by all necessary actions on the part of the
                Company. This instrument constitutes a legal, valid and binding obligation of the Company, enforceable against
                the Company in accordance with its terms, except as limited by bankruptcy, insolvency or other laws of general
                application relating to or affecting the enforcement of creditors’ rights generally and general principles of equity.
                To the knowledge of the Company, it is not in violation of (i) its current certificate of incorporation or governing
                documents, or (ii) any material indenture or contract to which the Company is a party or by which it is bound,
                where, in each case, such violation, individually, or together with all such violations, could reasonably be
                expected to have a material adverse effect on the Company.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c.</span> To the knowledge of the Company, the performance and consummation of the transactions
                contemplated by this instrument do not and will not: (i) violate any material judgment, statute, rule or regulation
                applicable to the Company as currently in effect; (ii) result in the acceleration of any material indenture or
                contract to which the Company is a party or by which it is bound; or (iii) result in the creation or imposition of
                any lien upon any property, asset or revenue of the Company or the suspension, forfeiture, or nonrenewal of any
                material permit, license or authorization applicable to the Company, its business or operations.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d.</span> To the knowledge of the Company, no consents or approvals are required in connection with the
                performance of this instrument, other than: (i) the Company’s corporate approvals; and (ii) any qualifications or
                filings under applicable securities laws.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e.</span> THE COMPANY MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO THE
                TOKENS, INCLUDING ANY (i) WARRANTY OF MERCHANTABILITY; (ii) WARRANTY OF FITNESS
                FOR A PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (iii) WARRANTY AGAINST
                INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER ARISING
                BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.
                EXCEPT AS EXPRESSLY SET FORTH HEREIN, PURCHASER ACKNOWLEDGES THAT IT HAS NOT
                RELIED UPON ANY REPRESENTATION OR WARRANTY MADE BY THE COMPANY, OR ANY
                OTHER PERSON ON THE COMPANY'S BEHALF.</p>
              <p className="darkpara"><span className="headingtext formarginleft">f.</span> THE   COMPANY,   INCLUDING   ITS   AFFILIATES   AND   REPRESENTATIVES,   HEREBY
                PROVIDES NOTICE THAT ANY FUNDS, CONSIDERATIONS, CONTRIBUTIONS, INCOME, PAYMENT,
                OR OTHER FINANCIAL BENEFITS DERIVED FROM THE SALE OF DOP TOKENS, WHETHER FROM A
                PRIVATE SALE, PUBLIC SALE, OR ANY OTHER MEANS ("RECEIVED FUNDS"), MAY BE UTILIZED
                AT THE ABSOLUTE DISCRETION OF THE COMPANY WITHOUT ANY RESTRICTION.
              </p>
              <p className="darkpara">
                FOR CLARITY, THIS INCLUDES, BUT IS NOT LIMITED TO, THE USE OF RECEIVED FUNDS FOR
                NON-BUSINESS-RELATED ENDEAVORS. NO REPRESENTATION, WARRANTY, OR ASSURANCE IS
                MADE BY THE COMPANY REGARDING THE SPECIFIC ALLOCATION OR UTILIZATION OF THE
                RECEIVED FUNDS FOR ANY PARTICULAR PURPOSE, INCLUDING ANY BUSINESS-RELATED
                OBJECTIVES.</p>
              <p className="darkpara"><span className="headingtext formarginleft">g.</span> ANY PARTY PURCHASING DOP TOKENS ACKNOWLEDGES AND AGREES THAT THE
                COMPANY RESERVES FULL DISCRETION OVER THE USAGE OF THE RECEIVED FUNDS. SUCH
                PURCHASERS EXPRESSLY WAIVE AND RELINQUISH ANY RIGHT TO RAISE CLAIMS AGAINST
                THE COMPNAY, ITS REPRESENTATIVES, SHAREHOLDERS, DIRECTORS, EMPLOYEES, SERVICE
                PROVIDERS, AFFILIATES, AND ANY RELATED PARTIES CONCERNING THE ALLOCATION OR
                UTILIZATION OF THE RECEIVED FUNDS.</p>
              <p className="darkpara"><span className="headingtext formarginleft">h.</span> The Company is not responsible for sustained losses due to vulnerability or any kind of failure,
                abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other features of the DOP
                Tokens. The Company is not responsible for sustained losses due to late reports by developers or representatives
                (or no report at all) of any issues with the blockchain supporting the DOP Tokens including forks, technical node
                issues or any other issues having fund losses as a result.</p>
              <p className="darkpara"><span className="headingtext formarginleft">i.</span> TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, TITLE, CURRENT OR FUTURE VALUE AND NON-
                INFRINGEMENT AS TO THE DOP TOKENS AND/OR ANY APPLICATION THEREOF.</p>
              <p className="darkparaupper">4. Purchaser Representations.</p>
              <p className="darkpara"><span className="headingtext formarginleft">a.</span> The Purchaser has full legal capacity, power and authority to execute and deliver this instrument and
                to perform its obligations hereunder, and over 18 years old. This instrument constitutes valid and binding
                obligation of the Purchaser, enforceable in accordance with its terms, except as limited by bankruptcy, insolvency
                or other laws of general application relating to or affecting the enforcement of creditors’ rights generally and
                general principles of equity.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b.</span> The Purchaser is purchasing this instrument for its own account, not as a nominee or agent, and not
                with a view to, or for resale in connection with, the distribution thereof, and the Purchaser has no present
                intention of selling, granting any participation in, or otherwise distributing the same. The Purchaser has such
                knowledge and experience in financial and business matters that the Purchaser is capable of evaluating the merits
                and risks of such investment, is able to incur a complete loss of such investment without impairing the
                Purchaser’s financial condition and is able to bear the economic risk of such investment for an indefinite period of
                time.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c.</span> The Purchaser hereby has sufficient knowledge and experience in business and financial matters to be
                able to evaluate the risks and merits of its engagement in this Agreement and the purchase of the Tokens and is
                able to bear the risks thereof. The Purchaser is aware of Company’s business affairs and financial condition and
                has acquired sufficient information about the Company to reach an informed and knowledgeable decision to
                acquire this Agreement. The Purchaser understands that the Tokens involve risks, all of which the Purchaser fully
                and completely assumes, including, but not limited to, the risk that (i) the technology to be developed by the
                Company will not function as intended; (ii) the intended development of the Company’s technology and Initial
                Launch will not be completed; (iii) the technology to be developed by the Company will fail to attract sufficient
                interest from key stakeholders; and (iv) the Company and/or the technology to be developed by the Company may
                be subject to investigation and punitive actions from Governmental Authorities. The Purchaser understands and
                expressly accepts that the Tokens will be created and delivered to the Purchaser at the sole risk of the Purchaser
                on an “AS IS” and “UNDER DEVELOPMENT” basis. The Purchaser understands and expressly accepts that the Purchaser has not relied on any representations or warranties made by the Company outside of this instrument,
                including, but not limited to, conversations of any kind, whether through oral or electronic communication, or any
                white paper. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE PURCHASER
                ASSUMES ALL RISK AND LIABILITY FOR THE RESULTS OBTAINED BY THE USE OF ANY TOKENS
                AND REGARDLESS OF ANY ORAL OR WRITTEN STATEMENTS MADE BY THE COMPANY, BY WAY
                OF TECHNICAL ADVICE OR OTHERWISE, RELATED TO THE USE OF THE TOKENS.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d.</span> The Purchaser understands that Purchaser has no right against the Company or any other Person
                except in the event of the Company’s breach of this instrument or intentional fraud. THE COMPANY’S
                AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS INSTRUMENT, WHETHER
                ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT OR OTHERWISE, SHALL NOT
                EXCEED THE TOTAL OF THE AMOUNTS PAID TO THE COMPANY PURSUANT TO THIS
                INSTRUMENT. NEITHER THE COMPANY NOR ITS REPRESENTATIVES SHALL BE LIABLE FOR
                CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR ENHANCED
                DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF OR
                RELATING TO ANY BREACH OF THIS INSTRUMENT.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e.</span> The Purchaser understands that Purchaser bears sole responsibility for any taxes or third party fees as a
                result of the matters and transactions the subject of this instrument, and any future acquisition, ownership, use,
                sale or other disposition of Tokens held by the Purchaser. To the extent permitted by law, the Purchaser agrees to
                indemnify, defend and hold the Company or any of its affiliates, employees or agents (including developers,
                auditors, contractors or founders) harmless for any claim, liability, assessment or penalty with respect to any taxes
                (other than any net income taxes of the Company that result from the issuance of Tokens to the Purchaser
                pursuant to Section 1(a) of the instrument) associated with or arising from the Purchaser’s purchase of Tokens
                hereunder, or the use or ownership of Tokens.</p>
              <p className="darkpara"><span className="headingtext formarginleft">f.</span> The purchaser agrees and acknowledges that the Company may sell tokens in private and public sales
                without restrictions at its discretion, at such prices (which may be lower or higher than the Purchase Price
                attributed to the Purchaser), and may, at any time at its discretion, continue or stop the private sales of Tokens.</p>
              <p className="darkpara"><span className="headingtext formarginleft">g.</span> The Purchaser is not a Prohibited Person and does not use Virtual Private Network (VPN) or other
                similar IP alternation service in accessing the Website and purchasing the Tokens.</p>
              <p className="darkparaupper">In this Agreement:</p>
              <p className="darkpara"><span className="headingtext formarginleft">“Prohibited Person”</span> means any U.S. Person, any person who is a resident of any Restricted
                Locations and any Sanctioned Person.</p>
              <p className="darkpara"><span className="headingtext">“U.S. Person”</span> means: </p>
              <p className="darkpara"><span className="headingtext formarginleft">a.</span> a U.S. Citizen or U.S. Resident;</p>
              <p className="darkpara"><span className="headingtext formarginleft">b.</span> a corporation, partnership, or other entity established or organized in or under the Laws of the
                United States;</p>
              <p className="darkpara"><span className="headingtext formarginleft">c.</span> any estate of a decedent who was a U.S. citizen or U.S. resident;</p>
              <p className="darkpara"><span className="headingtext formarginleft">d.</span> any trust if (i) a court within the United States is able to exercise primary supervision over the
                administration of the trust, and (ii) one or more United States Persons have the authority to
                control all substantial decisions of the trust;</p>
              <p className="darkpara"><span className="headingtext formarginleft">e.</span> any Person organized or incorporated outside the United States and the Territory or Insular
                Possession of the United States in which any of the foregoing, whether singularly or in the
                aggregate, directly or indirectly (i) holds a 50 percent or greater equity interest by votes or value, (ii) holds a majority of seats or memberships on the board of directors of the entity, or
                (iii) authorizes, establishes, directs, or otherwise controls the actions, policies, personnel
                decisions, or day-to-day operations of the Person;</p>
              <p className="darkpara"><span className="headingtext formarginleft">f.</span> any reference to the U.S. shall include all U.S.A. territories like Puerto Rico, American Samoa,
                Guam, Northern Mariana Island, and the US Virgin Islands (St. Croix, St. John and St.
                Thomas)</p>
              <p className="darkpara"><span className="headingtext">“Sanctioned Person”</span> refers to any person or digital tokens address that is: (i) specifically listed in
                any Sanctions List; (ii) directly or indirectly owned 50 percent or more by any person or group of
                persons in the aggregate, or a digital tokens wallet associated with such person or persons, referred
                to in any Sanctions List; or (iii) that is subject to any government approval or otherwise
                sanctioned, restricted, or penalized under applicable economic or financial sanctions of any type,
                anti money laundering laws, or counter terrorist financing laws;</p>
              <p className="darkpara"><span className="headingtext">“Sanctions List”</span> means any trade or economic sanctions lists of any Competent Authority, which
                includes without limitation: Terrorism and terrorist financing - Islamic State in Iraq and the Levant
                (Da’esh), Al-Qaida, the Taliban, and associated individuals, groups, undertakings, and entities, any
                individual or entity designated by the United Arab Emirates ("Local Terrorism List"); and the
                financing of proliferation of weapons of mass destruction (WMDs) - Democratic People’s
                Republic of Korea: nuclear-related, other weapons of mass destruction-related, and ballistic
                missile-related programs; Islamic Republic of Iran: nuclear program; and other sanction lists such
                as the United Nations Security Council Sanctions List, European Union, Hong Kong Monetary
                Authority, Hong Kong Customs and Excise Department, the Monetary Authority of Singapore
                (“MAS”), His Majesty’s Treasury (“HMT”), Government of Canada, U.S. Department of
                Treasury’s Office of Foreign Asset Control (“OFAC”) as well as other administrative law
                enforcement agencies.</p>
              <p className="darkpara"><span className="headingtext">“Restricted Locations”</span> shall   mean   the   comprehensively   sanctioned   jurisdictions   by   OFAC,
                namely, Cuba, Iran, North Korea, Syria, Crimea, so-called Donetsk People's Republic and Luhansk
                People's Republic regions of Ukraine, as well as the Bahamas, Bangladesh, Bolivia, Canada, Israel,
                Malaysia, Malta, the Netherlands and Singapore.</p>
              <p className="darkparaupper">5. Disclaimer</p>
              <p className="darkpara"><span className="headingtext formarginleft">a.</span> EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY THE
                COMPANY, THE TOKENS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE COMPANY
                (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE TOKENS WILL (1) MEET THE
                PURCHASER’S REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE,
                OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b.</span> WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR
                NOT TAKEN IN RELIANCE ON MATERIAL OR INFORMATION DELIVERED TO THE PURCHASER IN
                ANY MANNER. THE COMPANY DOES NOT REPRESENT OR WARRANT THAT ANY INFORAMTION
                DELIVERED TO THE PURCHASER IN ANY MANNER IS ACCURATE, COMPLETE, RELIABLE,
                CURRENT OR ERROR-FREE.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c.</span> WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
                RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF THE TOKENS
                INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (1) USER
                ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
                MISTYPED ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED WALLET FILES; (4)
                UNAUTHORIZED ACCESS TO APPLICATIONS; (5) ANY UNAUTHORIZED THIRD PARTY
                ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
                BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE TOKENS.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d.</span> THE TOKENS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF
                THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK (OR IN ANOTHER
                BLOCKCHAIN NETWORK). ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
                DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM
                (OR OTHER BLOCKCHAIN PLATFORM). WE DO NOT GUARANTEE THAT THE COMPANY OR ANY
                RELATED PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY TOKENS.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e.</span> DUE TO THE VOLATILE NATURE OF THE DIGITAL CURRENCIES MARKET IN
                GENERAL, AND THE EXTREMELY HIGH RISK ASSOCIATED WITH NEWLY ISSUED TOKENS IN
                PARTICULAR, THE COMPANY (OR ANYONE ON ITS BEHALF) CANNOT GUARANTEE THE VALUE
                OF THE DOP TOKENS OR THAT THE DOP TOKENS WILL MAINTAIN ITS VALUE OR ACCRUE ANY
                VALUE AT ANY TIME IN THE FUTURE. BY PURCHASING DOP TOKENS, PURCHASERS ARE
                AWARE AND AGREE THAT THE VALUE OF THE DOP TOKENS HELD BY THEM MAY BE
                DEPRECIATED TO ZERO, AND IN SUCH EVENT THE TOKEN HOLDERS WILL LOSE THE FUNDS IN
                WHICH THEY PURCHASED THE DOP TOKENS COMPLETELY AND IRREVERSIBLY. ANY RISK OF
                FLUCTUATION OR REDUCTION IN PRICE SHALL BE BORNE SOLELY BY THE FUTURE
                PURCHASERS OF DOP TOKENS.</p>
              <p className="darkpara"><span className="headingtext formarginleft">f.</span> The Company is not responsible for sustained losses due to vulnerability or any kind of failure,
                abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other features of the Tokens. The
                Company is not responsible for sustained losses due to late reports by developers or representatives (or no report
                at all) of any issues with the blockchain supporting the Tokens including forks, technical node issues or any other
                issues having fund losses as a result.</p>
              <p className="darkpara"><span className="headingtext formarginleft">g.</span> Nothing in this Agreement shall exclude or limit liability of either party for fraud, death or bodily
                injury caused by negligence, violation of laws, or any other activity that cannot be limited or excluded by
                legitimate means.</p>
              <p className="darkpara"><span className="headingtext formarginleft">h.</span> TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE TOKENS
                AND/OR ANY APPLICATION THEREOF.</p>
              <p className="darkpara"><span className="headingtext formarginleft">i.</span> THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
                OR LIMITED UNDER APPLICABLE LAW.</p>
              <p className="darkparaupper">6. Miscellaneous</p>
              <p className="darkpara"><span className="headingtext formarginleft">a.</span> This instrument sets forth the entire agreement and understanding of the parties relating to the subject
                matter herein and supersedes all prior or contemporaneous disclosures, discussions, understandings and
                agreements, whether oral of written, between them. This instrument is one of a series of similar instruments
                entered into by the Company from time to time. Any provision of this instrument may be amended, waived or
                modified only upon the written consent of the Company and the holders of a majority, in the aggregate, of the
                Purchase Amounts paid to the Company with respect to all Private Sale Agreements outstanding at the time of
                such amendment, waiver or modification.</p>
              <p className="darkpara"><span className="headingtext formarginleft">b.</span> The Purchaser is not entitled, as a holder of this instrument, to vote or receive dividends or be deemed
                the holder of shares or capital stock of the Company for any purpose, nor will anything contained herein be
                construed to confer on the Purchaser, as such, any of the rights of a shareholder or a stockholder of the Company
                or any right to vote for the election of directors or upon any matter submitted to stockholders at any meeting
                thereof, or to give or withhold consent to any corporate action or to receive notice of meetings, or to receive
                subscription rights or otherwise.</p>
              <p className="darkpara"><span className="headingtext formarginleft">c.</span> Neither this instrument nor the rights contained herein may be assigned, by operation of law or
                otherwise, by either party without the prior written consent of the other; and provided, further, that the Company
                may assign this instrument in whole, without the consent of the Purchaser, in connection with its actual
                incorporation.</p>
              <p className="darkpara"><span className="headingtext formarginleft">d.</span> In the event any one or more of the provisions of this instrument is for any reason held to be invalid,
                illegal or unenforceable, in whole or in part or in any respect, or in the event that any one or more of the
                provisions of this instrument operate or would prospectively operate to invalidate this instrument, then and in any
                such event, such provision(s) only will be deemed null and void and will not affect any other provision of this
                instrument and the remaining provisions of this instrument will remain operative and in full force and effect and
                will not be affected, prejudiced, or disturbed thereby.</p>
              <p className="darkpara"><span className="headingtext formarginleft">e.</span> All rights and obligations hereunder will be governed by the laws of Hong Kong, without regard to the
                conflicts of law provisions of such jurisdiction.</p>
              <p className="darkpara"><span className="headingtext formarginleft">f.</span> The Purchaser shall, and shall cause its affiliates to, execute and deliver such additional documents,
                instruments, conveyances and assurances and take such further actions as may be reasonably requested by
                Company to carry out the provisions of this instrument and give effect to the transactions contemplated by this
                instrument, including, without limitation, to enable the Company or the transactions contemplated by this
                instrument to comply with applicable laws.</p>
              <p className="darkpara"><span className="headingtext formarginleft">g.</span> The Company shall not be liable or responsible to the Purchaser, nor be deemed to have defaulted
                under or breached this instrument, for any failure or delay in fulfilling or performing any term of this instrument,
                including without limitation, Initial Launch, when and to the extent such failure or delay is caused by or results
                from acts beyond the affected party's reasonable control, including, without limitation: (a) acts of God; (b) flood,
                fire, earthquake or explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or
                acts, or other civil unrest; (d) Law; or (e) action by any Governmental Authority.</p>
            </div>
          </div>
          {/* {checkedNew&& */}
              <div className="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input type="checkbox" onChange={inputField1Handler2}  id="ch1" />
                  <div class="transition"></div>
                </label>
              </div>
              <p className="checkboxpara">I have carefully reviewed the Private Sale Agreement, including its disclaimers, and fully agree to abide by its terms and conditions.</p>
            </div>

          {/* } */}
        
          {/* {
            cheked &&
            <div className="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input type="checkbox" id="ch1" onChange={inputField1Handler3} />
                  <div class="transition"></div>
                </label>
              </div>
              <p className="checkboxpara">I hereby confirm that I am not a citizen or a resident of the United States of America or the State of Israel.</p>
            </div>
          } */}
          {cheked  ?
            <button  className="continuebutton" onClick={()=>submitt()}>continue</button>
            :
            <button className="disablebutton">continue</button>
          }

        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
