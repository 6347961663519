import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login/Login';
import Claim from './components/Claim/Claim';
import useEagerConnect from "./hooks/useEagerConnect";
import ClaimNft from './components/Claim/ClaimNft';
import Welcome from './components/Welcome/Welcome.js';
import Eligible from './components/Eligible/Eligible.js';
import Verify from './components/Verify/Verify.js';
import Welcometimer from './components/Welcometimer/Welcometimer';
function App() {
  let timer = localStorage.getItem("timer")
  useEagerConnect();
  return (
    <>
        <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
        <Router>
          <Switch>
          <Route exact path='/' component={timer?Welcome:Welcometimer} />
          <Route exact path='/eligible' component={Eligible} />
            {/* <Route exact path='/' component={Login} /> */}
            <Route exact path='/buy' component={Landing} />
            <Route exact path='/claim' component={Claim} />
            <Route exact path='/ClaimNft' component={ClaimNft} />
            <Route exact path='/verify' component={Verify} />
            {/* <Route exact path='/welcome' component={Welcome} /> */}
          </Switch>
        </Router>
      </>
      );
}

      export default App;
