import React, { useEffect } from 'react'
import './welcome.scss'
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import useAuth from '../../hooks/useAuth'
import { useHistory } from "react-router-dom";
const Welcome = () => {
  let timerrr = localStorage.getItem("timer")
    const history = useHistory()
    const { account } = useWeb3React()
    const { login, logout } = useAuth();
    const trustWallet = async () => {
        if (account) {
          await logout("walletconnect");
          localStorage.removeItem("connectorId");
          localStorage.removeItem("flag");
          localStorage.removeItem("buyCheck");
          localStorage.removeItem("location");
        } else {
          try {
             login("walletconnect"); 
            // handleClose();
            localStorage.setItem('connectorId', 'walletconnect');
            localStorage.setItem("flag", "true");
          } catch (err) {
            console.log("eerr", err);
          }
        }
      };

      const connectMetaMask1 = async () => {
        if (account) {
          const connectorId = window.localStorage.getItem("connectorId")
          await logout(connectorId);
          localStorage.removeItem("connectorId");
          localStorage.removeItem("flag");
        } else {
          login("injected");
          localStorage.setItem("connectorId", "injected");
          localStorage.setItem("flag", "true");
        //   handleClose();
    
    
        }
      };
      useEffect(()=>{
        if(account){
            history.push("/eligible");
            localStorage.setItem('location',true)
        }
      },[account])
      useEffect(()=>{
        if(!timerrr){
          history.push("/");
        }
      },[])
  return (
    <>
    <div className="welcomemainwrapper">
    <Navbar />
    <section className="mainwelcome">
        <div className="innerwelcome">
            <img src="\assets\doplogo.svg" alt="logoimg" className="logoimg" />
            <h4 className="welcomemainhead">Welcome DOP testnet users!</h4>
            <p className="welcomemainpara">You've played an important role in our journey. This exclusive sale is our way of saying thank you.</p>
            <p className="connectpara">Connect the wallet that you used in the testnet</p>
            <div className="walletsmain">
                {/* <Link className='w-100' to="/eligible"> */}
                <div onClick={connectMetaMask1} className="innerwallet hidMetamask">
                    <p className="deskmobpara">Desktop</p>
                    <img src="\assets\metamaskicon.svg" alt="walletimg" className="walletimg" />
                    <h6 className="wallethead">MetaMask</h6>
                </div>
                {/* </Link> */}
                {/* <Link className='w-100' to="/eligible"> */}
                <div onClick={trustWallet} className="innerwallet">
                    <p className="deskmobpara">Mobile</p>
                    <img src="\assets\walletconnecticon.svg" alt="walletimg" className="walletimg" />
                    <h6 className="wallethead">WalletConnect</h6>
                </div>
                {/* </Link> */}
            </div>
        </div>
    </section>
    </div>
    </>
  )
}

export default Welcome