import React, { useEffect, useState } from "react";
import "./claim.scss";
import Navbar from "../landing/header/Navbar";
import { Accordion, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Claim1 from "../../hooks/chinaFunction/claim";
import ClaimAll from "../../hooks/chinaFunction/claimAll";
import { array } from "i/lib/util";
import { toast } from "react-toastify";
import EthPrice from "../../hooks/chinaFunction/ethPrice";
import { intersection } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Environment from "../../utils/Environment";
import { setupNetwork } from "../../utils/wallet";
const Claim = () => {
  let timerrr = localStorage.getItem("timer")
  useEffect(()=>{
    if(!timerrr){
      history.push("/");
    }
  },[])
  let locationn=localStorage.getItem('buyCheck')
  const [ethPricevalue, setEthPrice] = useState(0);
  const { ethPrice } = EthPrice();
  let now = Date.now();
  const role = localStorage?.getItem("role_ins");
  const { claim1 } = Claim1();
  const { claimAll } = ClaimAll();
  const [pageCount, setPageCount] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalArry, settotalArry] = useState([]);
  const { account,chainId } = useWeb3React();
  const [mainLoader, setMainLoader] = useState(false);
  const [mainLoader1, setMainLoader1] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [check, setCheck] = useState(false);
  const [showerror, setShowerror] = useState(false);

  const handleCloseerror = () => setShowerror(false);
  const handleShowerror = () => setShowerror(true);
  // const handlePageClick = (e) => {
  //   setMainLoader1(true);
  //   const selectedPage = e.selected;
  //   let skip = "";
  //   if (e.selected > 0) {
  //     skip = JSON.stringify(e.selected * limit);
  //     setPage(selectedPage);
  //   } else {
  //     skip = JSON.stringify(0);
  //     setPage(0);
  //   }
  //   var data = JSON.stringify({
  //     query: `query MyQuery {
  //       investments(
  //         where: {by: "${account}"}
  //         orderBy: blockTimestamp
  //         orderDirection: desc
  //         first:10
  //         skip: ${skip}
  //       ) {
  //         blockTimestamp
  //         amountInvested
  //         by
  //         code
  //         dopPurchased
  //         id
  //         price
  //         transactionHash
  //         tokenSelected
  //         round
  //       }
  //     }`,
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/dop",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setPageCount(totalLength / limit);
  //       settotalArry(response?.data?.data?.investments);
  //       ClaimAll111();
  //       setMainLoader1(false);
  //     })
  //     .catch(function (error) {
  //       setMainLoader1(false);
  //     });
  // };
  // const nftMainArryHandler1 = async () => {
  //   setMainLoader1(true);
  //   var data = JSON.stringify({
  //     query: `query MyQuery {
  //       investments(
  //         where: {by: "${account}"}
  //         orderBy: blockTimestamp
  //         orderDirection: desc
  //       ) { blockTimestamp
  //         amountInvested
  //         by
  //         code
  //         dopPurchased
  //         id
  //         price
  //         transactionHash
  //         tokenSelected
  //         round
  //       }
  //     }`,
  //     variables: {},
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/dop",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   await axios(config)
  //     .then(async function (response) {
  //       console.log(response,'response?.data?.data?.investments');
  //       setPageCount(response?.data?.data?.investments?.length / limit);

  //       setTotalLength(response?.data?.data?.investments?.length);
  //       setMainLoader1(false);
  //     })
  //     .catch(function (error) {
  //       setMainLoader1(false);
  //       console.log(error, "error");
  //     });
  // };
  const code = localStorage?.getItem("accessCode");
  const email = localStorage?.getItem('email')
  // useEffect(()=>{
  //   // console.log('usefffef',email,code);
  //   if(!email||!code){
  //   history.push('/')
  //   }
  // },[])
  useEffect(() => {
    //  console.log(chainId,'chainIdchainId');
    if (account){
    if (chainId!=1)
   {
    setupNetwork()
   }
    }
  }, [account,chainId])
  const nftMainArryHandler2latest = async () => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        purchasedWithETHs(
          where: {by: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          amountPurchasedEth
          blockNumber
          blockTimestamp
          by
          dopPurchased
          id
          transactionHash
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/67357/doptestround/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log(response?.data?.data?.purchasedWithETHs, 'response?.data?.data?.investments mainn1');
        // nftMainArryHandler2latestFromAdmin(response?.data?.data?.investments)
        settotalArry(response?.data?.data?.purchasedWithETHs);
        setMainLoader1(false);
        // ClaimAll111();
        // settotalArry(response?.data?.data?.investments);
        // instittion(response?.data?.data?.investments)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };
  const nftMainArryHandler2latestFromAdmin = async (e) => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        investedWithClaimAmounts(
          where: {by: "${account}"}
        ) {
          round
          price
          id
          dopPurchased
          by
          blockTimestamp
          blockNumber
          amountUsd
          amountEth
          transactionHash
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/51954/dopgraphcombined/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response, 'response?.data?.data?.investments mainn1 sddasasa');
        let a = response?.data?.data?.investedWithClaimAmounts
        let c = e.concat(a);
        nftMainArryHandler2(c)
        // settotalArry(response?.data?.data?.investedWithClaimAmounts);
        // setMainLoader1(false);
        // ClaimAll111();
        // settotalArry(response?.data?.data?.investments);
        // instittion(response?.data?.data?.investments)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };

  const nftMainArryHandler2 = async (e) => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        investments(
          where: {by: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          transactionHash
          tokenSelected
          round
          price
          id
          dopPurchased
          code
          by
          blockTimestamp
          amountInvested
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/51954/dopgraphcombined/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response, 'response?.data?.data?.investments mainn1');
        // settotalArry(response?.data?.data?.investments);
        // setMainLoader1(false);
        // ClaimAll111();
        // settotalArry(response?.data?.data?.investments);
        let a = response?.data?.data?.investments
        let c = e.concat(a);
        instittion(c)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };
  const instittion = async (e) => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        preSaleDopInstitutionInvestments(
          where: {recipient: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
        ) { 
          price
          by
          code
          dopPurchased
          id
          recipient
          tokenSelected
          transactionHash
          blockTimestamp
          amountInvested
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/51954/dopgraphcombined/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response, 'response?.data?.data?.investmentssdfsdf main2');
        // settotalArry(response?.data?.data?.investments);
        // setMainLoader1(false);
        // ClaimAll111();
        // settotalArry(response?.data?.data?.preSaleDopInstitutionInvestments);
        let a = response?.data?.data?.preSaleDopInstitutionInvestments
        let c = e.concat(a);
        Newinstittion(c)
        // nftMainArryHandler22(c)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });





  }
  const Newinstittion = async (e) => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        investments(
          where: {recipient: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
        ) { 
          transactionHash
          tokenPrice
          recipient
          price
          id
          dopPurchased
          code
          by
          blockTimestamp
          amountInvested
          token
          tokenSelected
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/presaledopinstitution",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log(response, 'response?.data?.data?.investmentssdfsdf main222');
        // settotalArry(response?.data?.data?.investments);
        // setMainLoader1(false);
        // ClaimAll111();
        // settotalArry(response?.data?.data?.preSaleDopInstitutionInvestments);
        let a = response?.data?.data?.investments
        let c = e.concat(a);
        helllo12(c)
        // nftMainArryHandler22(c)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });





  }

  const helllo12 = async (e) => {
    var data = JSON.stringify({
      query: `query MyQuery {
            investments(where: {by: "${account}"}
        orderBy: blockTimestamp
        orderDirection: desc
            ) {
              amountInvested
              nftAmounts
              round
              tokenSelected
              by
              blockTimestamp
              roundPrice
              type
              transactionHash
              id
              code
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/proxy/55946/presaledopnft/0.0.1",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response, 'noooor');
        // settotalArry(response?.data?.data?.investments);
        // setMainLoader1(false);
        // ClaimAll111();
        let a = response?.data?.data?.investments
        let c = e.concat(a);
        nftMainArryHandler22(c)
        // nftMainArryHandler22(c)
        // instittion(response?.data?.data?.investments)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };

  const nftMainArryHandler22 = async (e) => {
    // setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        investments(
          where: {by: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          tokenSelected
          transactionHash
          round
          price
          index
          id
          dopPurchased
          code_code
          code_SuperAgentPercentage
          code_SuperAgentAddress
          code_MegaAgentPercentage
          code_MegaAgentAddress
          code_GodAgentPercentage
          code_GodAgentAddress
          code_AgentPercentage
          code_AgentAddress
          by
          blockTimestamp
          SuperAgentAmount
          amountInvested
          MegaAgentAmount
          GodAgentAmount
          AgentAmount
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        let a = response?.data?.data?.investments
        let c = e.concat(a);
        c.sort((a, b) => {
          // console.log(a,b,'sorting');
          return b?.blockTimestamp - a?.blockTimestamp
        })
        //  console.log(response,'response main3');
        settotalArry(c);
        setMainLoader1(false);
        // ClaimAll111();
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };


  useEffect(() => {
    if (account) {
      // nftMainArryHandler2()
      nftMainArryHandler2latest()
      firstTimeData()

    } else {
      settotalArry(null)
    }
  }, [account, ethPricevalue]);

  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handler121 = async (e) => {
    setMainLoader(true);
    let z = e.currentIndex;
    try {
      let t = await claim1(z);
      if (t) {
        setMainLoader(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err, "err");
      setMainLoader(false);
      toast.error("Transaction reverted");
    }
  };
  const ClaimAll11 = async (e) => {
    let dummy = [];
    totalArry.map((e, i) => {
      if (now > e?.ReleaseTime * 1000 && e?.isClaimed == false) {
        // console.log(e, "e");
        dummy.push(e.currentIndex);
      }
    });
    if (dummy?.length > 0) {
      setMainLoader(true);
      try {
        let b = await claimAll(dummy);
        if (b) {
          setMainLoader(false);
          window.location.reload();
        }
      } catch (err) {
        setMainLoader(false);
        console.log(err, "err");
        toast.error("Transaction reverted");
      }
    }
  };
  const ClaimAll111 = async (e) => {
    let dummy = [];
    totalArry.map((e, i) => {
      if (now > e?.ReleaseTime * 1000 && e?.isClaimed == false) {
        dummy.push(e.currentIndex);
      }
    });
    if (dummy?.length > 0) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };



  const firstTimeData = async () => {
    try {
      let a = await ethPrice();
      setEthPrice(a)
    } catch (err) {
      console.log(err.message);
    }
  };


  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  // useEffect(()=>{
  //   if(!locationn){
  //       history.push("/");
  //   }
  // },[account])
  // useEffect(() => {
    //  console.log(chainId,'chainIdchainId');
  //   if (account){
  //   if (chainId!=1)
  //  {
  //   setupNetwork()
  //  }
  //   }
  // }, [account,chainId])

  const [show1, setShow1] = useState(false);
  const handleClose = () => {
    setShow1(false);
  };
  const handleShow = () => setShow1(true);
  return (
    <>
      {mainLoader1 && (
        <Loader />
      )}
      {mainLoader && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar show={show} setShow={setShow} />
      <section className="claim">
        <div className="custom-container">
          <div className="claim-heading">
            <h6>Claim DOP</h6>
<button className="claimallbtn">Claim All</button>
          </div>
          <div className="bottom-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Purchased on</th>
                    <th>PURCHASE PRICE</th>
                    <th>Amount Spent</th>
                    <th>TOKEN PURCHASED</th>
                    <th>TYPE</th>
                    <th>Expected on</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                  {totalArry?.map((e) => {
                     let puchased = (parseInt(e?.amountPurchasedEth) / 10 ** 18).toFixed(
                      5
                    )
                let totalPric =(e?.dopPurchased / 10 ** 18).toFixed(5)
                // let selectedType
                let a = parseInt(e?.blockTimestamp);
                let cc = new Date(a);
                const StackIntialDate = moment(cc * 1000).format(
                  "DD-MM-YYYY H:mm"
                );
               
                // let selectedTypeCalculation=dopPurchased
                // if(e?.token?.toLowerCase()===Environment?.usdc?.toLowerCase()||Environment.wbtc?.toLowerCase()===e?.token?.toLowerCase()){
                //   selectedTypeCalculation = parseFloat(e.tokenPrice) / 10 ** 8
                // }else{

                //   selectedTypeCalculation = parseFloat(e.tokenPrice) / 10 ** 10
                // }

     
                // // console.log(Environment.tomi,e?.token);
                // if (e?.tokenSelected === "ETH") {
                //   if (e?.token === "0x"|| e.token===Environment.eth.toLocaleLowerCase()) {
                //     puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(4);
                //     totalPric = (puchased * selectedTypeCalculation / ((e?.roundPrice) / 10 ** 18)).toFixed(4)
                //   }
                //   puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(4);
                //   totalPric = (puchased * ethPricevalue / ((e?.roundPrice) / 10 ** 18)).toFixed(4)
                // } else if (e?.tokenSelected === "TOKEN") {
                //   if (e?.token?.toLowerCase() === Environment.tomi?.toLowerCase()) {
                //     puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(
                //       4
                //     );
                //     selectedType = "TOMI"
                //   } else  if (e?.token?.toLowerCase()===Environment?.busd?.toLowerCase())       
                //         {
                //     puchased = (parseInt(e?.amountInvested) / 10 ** 6).toFixed(
                //       4
                //     );
                //     selectedType = "USDT"
                //   }
                //   else  if (e?.token?.toLowerCase()===Environment?.usdc?.toLowerCase())       
                //                 {
                //             puchased = (parseInt(e?.amountInvested) / 10 ** 6).toFixed(
                //               4
                //             );
                //             selectedType = "USDC"
                //           }
                //           else  if (e?.token?.toLowerCase()===Environment?.wbtc?.toLowerCase())       
                //                 {
                //             puchased = (parseInt(e?.amountInvested) / 10 ** 8).toFixed(
                //               4
                //             );
                //             selectedType = "WBTC"
                //           }
                //   else{
                //    puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(
                //      4
                //    );
                //    selectedType = "ETH"
     
                //    }
     
                //   // console.log(selectedTypeCalculation);
                //   totalPric = (puchased * selectedTypeCalculation / ((e?.roundPrice) / 10 ** 18)).toFixed(4);
                //   // e?.tokenSelected="TOMI"
     
                // }
     
                //  else {
                //  puchased = (parseInt(e?.amountInvested) / 10 ** 6).toFixed(4);
                //  totalPric = (puchased / ((e?.roundPrice) / 10 ** 18)).toFixed(4);
                 // e?.tokenSelected="USDT"
              //  };
                    return (
                      <>
                        <tr>
                          <td>{StackIntialDate}</td>
                          <td>$  0.082{" "}/DOP</td>
                          <td>
                            {puchased?puchased:'' } {"ETH"}
                          </td>
                          <td>
                            {totalPric?totalPric:''}{" "}
                            DOP
                          </td>
                          {/* <td>{e?.type === "NFT" ? "NFT" : "TOKEN"}</td>
                          <td>Q1,2024</td> */}
 <td>
                            {"TOKEN"}
                          </td>
                          <td>
                          Q1,2024
                          </td>

                          {now > e?.ReleaseTime * 1000 ? (
                            <>
                              {e.isClaimed == false ? (
                                <td>
                                  <button
                                    className="btn-claim"
                                    onClick={() => {
                                      handler121(e);
                                    }}
                                  >
                                    Claim
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button className="btn-claim claimed">
                                    Claimed
                                  </button>
                                </td>
                              )}
                            </>
                          ) : (
                            <td>
                              <button className="btn-claim disable">
                                Claim
                              </button>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              {/* <div className="footer-content">
                <div className="left-f">
                  <h6 className="paginationtext">
                    SHOWING {page + 1}-{limit} OF {total}
                  </h6>
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    {pageCount >= 1 ? (
                      <div className="text-center">
                        <ReactPaginate
                          previousLabel={<img src="\assets\arrow1.svg" />}
                          nextLabel={<img src="\assets\arrow2.svg" />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </div>
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div> */}
            </div>
            <div className="accmblview d-none">
              <h6 className="accmblviewhead">
                Purchased on{" "}
              </h6>
              {totalArry?.map((e) => {
                        // let totalPric = 0
                        let selectedType
                        let a = parseInt(e?.blockTimestamp);
                        let cc = new Date(a);
                        const StackIntialDate = moment(cc * 1000).format(
                          "DD-MM-YYYY H:mm"
                        );
                        let puchased = (parseInt(e?.amountPurchasedEth) / 10 ** 18).toFixed(
                          5
                        )
                    let totalPric =(e?.dopPurchased / 10 ** 18).toFixed(5)
   
                     

return (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="accheadermain">
                            <p className="acctext">{StackIntialDate}</p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft">PURCHASE PRICE</h6>
                            <p className="textright">$ 0.082{" "}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Amount Spent</h6>
                            <p className="textright">  {!puchased||puchased=='NaN'?`${e?.amountUsd?e?.amountUsd/10**6:0} USDT + ${e?.amountEth?e?.amountEth/10**18:0} ETH ` :puchased } {selectedType ? selectedType : e?.tokenSelected}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft"> PURCHASED</h6>
                            <p className="textright">
                              {

                                ((e?.dopPurchased) / 10 ** 18).toFixed(4)
                              }{" "} DOP</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">TYPE</h6>
                            <p className="textright">{e?.type === "NFT" ? "NFT" : "TOKEN"}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Expected on</h6>
                            <p className="textright">Q1,2024</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Action</h6>
                            <button className="btn-claim disable">Claim</button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                );
              })}

              {/*          
              <div className="footer-content">
                <div className="left-f">
                  <h6 className="paginationtext">
                    SHOWING {page + 1}-{limit} OF {total}
                  </h6>
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    {pageCount >= 1 ? (
                      <div className="text-center">
                        <ReactPaginate
                          previousLabel={<img src="\assets\arrow1.svg" />}
                          nextLabel={<img src="\assets\arrow2.svg" />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </div>
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claimed Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\purchased.svg" alt="img" className="img-fluid" />
            <p>
            You have successfully Claimed <br />
              <span>
              2,189,426.24 DOP </span>
              {/* You can claim your DOP on <span>Q1,2024</span> */}
            </p>
            <button className="btn-common" onClick={handleClose}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="connectwallet-modal buy-modal"
        show={showerror}
        onHide={handleCloseerror}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\newtryagain.png" alt="img" className="img-fluid" />
            <p>
              Oops, something went wrong
            </p>
            <button className="btn-common" onClick={handleCloseerror}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Claim;
