import web3NoAccount from "./web3";
import ChinaAbi from "./chinaAbi.json";
import BusdAbi from "./busdAbi.json";
import TomiAbi from "./tomiAbi.json";
import UsdcAbi from "./usdcAbi.json";
import WbtcAbi from "./wbtcAbi.json";
import Institusion from "./institusion.json";
import NftPurchase from './nftPurchase.json'
const getContract = (abi, address, web3) => {
  const _web3 = web3;
  // console.log('_web3',_web3);
  return new _web3.eth.Contract(abi, address);
};

export const chinaContract = (address, web3) => {
  return getContract(ChinaAbi, address, web3);
};

export const usdtContract = (address, web3) => {
  return getContract(BusdAbi, address, web3);
};
export const tomiContract = (address, web3) => {
  return getContract(TomiAbi, address, web3);
};
export const usdcContract = (address, web3) => {
  return getContract(UsdcAbi, address, web3);
};
export const wdtcContract = (address, web3) => {
  return getContract(WbtcAbi, address, web3);
};
export const InstitusionContract = (address, web3) => {
  return getContract(Institusion, address, web3);
};
export const NftPurchaseContract = (address, web3) => {
  return getContract(NftPurchase, address, web3);
};
