let Environment = {
  nftPurchase: "0x1119c2d708E42Fb591FCe77283b0791a135855ba",
  chinaTomi: "0x889E7a3B54649a84EcBfce1edB5f5389988D1CE6",
  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  instituson: "0x3e3Fbf2E10f3f3399c74180803e6721eC20d5c65",
  eth:"0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  wbtc:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
};
export default Environment;
