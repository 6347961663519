import React, { useEffect, useState } from 'react'
import './eligible.scss'
import Navbar from '../landing/header/Navbar'
import { Link, useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { API_URL } from "../../utils/ApiUrl";
import axios from "axios";
import Loader from '../../hooks/loader'
import { Modal } from 'react-bootstrap'
const Eligible = () => {
  let timerrr = localStorage.getItem("timer")
  useEffect(()=>{
    if(!timerrr){
      history.push("/");
    }
  },[])
  const [maxPrice, setMaxPrice] = useState(null)
  const [check, setCheck] = useState(null)
  const { account } = useWeb3React()
  const [loaderr1, setLozder1] = useState(true)
  let locationn = localStorage.getItem('location')
  const history = useHistory()
  useEffect(() => {
    if (!locationn) {
      history.push("/");
    }
  }, [account])

  useEffect(() => {
    if (account) {
      stepTwoactive()
    }
    else if (account == undefined) {

      history.push("/");
    }
  }, [account])
  const stepTwoactive = async () => {
    setLozder1(true)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}testnet/buying-limit?walletAddress=${account?.toLowerCase()}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data.data[0].reward, 'response');
        localStorage.setItem('buyCheck', true)
        setCheck(true)
        setMaxPrice(parseFloat(response?.data?.data[0]?.reward))
        setLozder1(false)


      })
      .catch((error) => {
        console.log(error);
        setLozder1(false)

      });

  }

  const [showwaitlist, setShowwaitlist] = useState(false);

  const handleClosewaitlist = () => setShowwaitlist(false);
  const handleShowwaitlist = () => setShowwaitlist(true);

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
      const interval = setInterval(() => {
          const now = new Date();
          const time = new Date("june 23, 2024 18:00:00");
          const diff = time.getTime() - now.getTime();
          if (diff <= 0) {
              clearInterval(interval);
              setTimeshow(true);
              return;
          }
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const secs = Math.floor((diff % (1000 * 60)) / 1000);
          setDay(days);
          setHour(hours);
          setMin(mins);
          setSec(secs);
      }, 1000);
      return () => clearInterval(interval);
  }, []);
  return (
    <>
      {loaderr1 && (
        <Loader />
      )}
      <div className="eligiblewrapper">
        <Navbar />
        <section className="maineligible">
          <div className="innereligible">
            <div className="uppereligible">
              <h6 className="eligiblepara">Purchase Eligibility</h6>
              <img src="\assets\doplogo.svg" alt="logoimg" className="logoimg" />
            </div>
            <div className={check ? 'bottomeligible paddingset' : 'bottomeligible'}>
              {check ?
                <p className="bottompara">Awesome! You are eligible to buy in.</p>
                :
                <p className="bottompara">Sorry! You are not eligible to purchase.</p>
              }


              {/* if not eligible use this para */}
              {/* <p className="noteliblepara">Sorry! You are not eligible to purchase.</p> */}
              {check ?
                <h4 className="bottomhead">{maxPrice?.toFixed(2)} ETH</h4>
                :
                <h4 className="noteligblehead">0.00 ETH</h4>
              }


              {/* if not eligible use this h4 */}
              {/* <h4 className="noteligblehead">0.00 ETH</h4> */}
              {check ?
               <a className="w-100" onClick={()=>{
                history.push("/buy");
                window.location.reload()
              }}>
                <button className="greybtn">Continue</button>
              </a>
                :
                ""

              }

              {/* if not eligible use this para */}
              {
                !check ?
                  <p className="samewalletpara">Please make sure that you followed DOP on the twitter From the Same account That you followed on DOP Testnet.</p>
                  :
                  ""
              }

            </div>
          </div>
        </section>
      </div>

      {/* <button onClick={handleShowwaitlist}>dsf</button> */}
      <Modal
        className="connectwallet-modal waitlist-modal"
        show={showwaitlist}
        onHide={handleClosewaitlist}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy $DOP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <h6 className="waitlisthead">You are not on the waitlist.</h6>
            <p className="waitlistpara">You will be able to buy in</p>

            <div className="waitlisttimer">
                        {timeshow ? (
                            <p className="endpara">Timer has ended!</p>
                        ) : (
                            <div className="timerspans">
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{hour ? hour : 0}</span>
                                    </div>
                                    <p className="timertext">HOURS</p>
                                </div>
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{min ? min : 0}</span>
                                    </div>
                                    <p className="timertext">MINUTES</p>
                                </div>
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{sec ? sec : 0}</span>
                                    </div>
                                    <p className="timertext">SECONDS</p>
                                </div>
                            </div>
                        )}
                    </div>
            <button className="btn-try" onClick={handleClosewaitlist}>
              Try Again
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Eligible