import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import { toast } from "react-toastify";

const PurchaseEth = () => {
  // const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  const purchaseEth = useCallback(
    async (min,amount, postApiRes,id,balance) => {

    let amount11 = parseFloat(amount).toFixed(6)
      let amount1 = Math.round((amount11) * 1e12) / 1e12;
      let nd = web3.utils.toWei(amount1.toString(), "ether");
      let minvalue=min*0.99
      minvalue= parseFloat(minvalue).toFixed(6)
      minvalue=Math.round((minvalue) * 1e12) / 1e12;
      // console.log(minvalue,'minvalue');
      minvalue= web3.utils.toWei(minvalue.toString(), "ether");
      // console.log(postApiRes.max,id,postApiRes.deadline,minvalue,postApiRes?.v, postApiRes?.r, postApiRes?.s,'id,balance');
      // console.log(nd,minvalue,postApiRes.code,id,postApiRes.deadline, postApiRes?.v, postApiRes?.r, postApiRes?.s,min);
      // let tuple = {
      // code:postApiRes.code,
      // GodAgentPercentage:postApiRes.gotAgentPercentage,
      // MegaAgentPercentage:postApiRes.megaAgentPercentage,
      // SuperAgentPercentage:postApiRes.superAgentPercentage,
      // AgentPercentage:postApiRes.agentPercentage,
      // GodAgentAddress:postApiRes.gotAgentAddress,
      // MegaAgentAddress:postApiRes.megaAgentAddress,
      // SuperAgentAddress:postApiRes.superAgentAddress,
      // AgentAddress:postApiRes.agentAddress,

      // };
      let gas
      // console.log(postApiRes?.code,id,postApiRes.deadline,minvalue, postApiRes?.v, postApiRes?.r, postApiRes?.s,'nooor');
      try {
        let gasPrice = await web3.eth.getGasPrice()
        gasPrice = parseInt(gasPrice) + 5000000000
         gas = await contract.methods
          .purchaseTokenWithEth(postApiRes.deadline,minvalue, postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .estimateGas({
            value: nd,
            from: account,
            gasPrice
          });
        const details = await contract.methods
          .purchaseTokenWithEth(postApiRes.deadline,minvalue,postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .send({
            value: nd,
            from: account,
            gas: gas,
            gasPrice
          });
        return details;
      } catch (error) {
        // let gasPrice1=await web3.eth.getGasPrice()
        // let gasPrice = parseInt(gasPrice1) + 3000000000
        // gas=gas*gasPrice
        // let nd = web3.utils.fromWei(gas.toString(), "ether");
        // console.log(gas,nd,balance );
        // if(nd>balance){
        //    toast.error("Insufficient ETH for Transaction")
        // }else{
          throw error;
        // }
      }
    },
    [contract, account,web3]
  );
  return { purchaseEth: purchaseEth,web3 };
};
export default PurchaseEth;
