// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';

import { useHistory } from "react-router-dom";
import { useWeb3React } from '@web3-react/core';
function Landing() {
  const [show, setShow] = useState(false);
  const history = useHistory()

  const { account } = useWeb3React()
 
  // useEffect(() => { 
  //   window.scrollTo(0, 0)
  //   if(!checkadmin)
  //   {
  //     history.push("/");
  //   }
  // }, [])
  let locationn=localStorage.getItem('buyCheck')
  useEffect(()=>{
    if(!locationn){
        history.push("/");
    }
  },[account])

  return (
    <>
    
    <Navbar show={show} setShow={setShow}/>
      <Banner setShow={setShow}/>
    </>
  );
}

export default Landing;